import { Button } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";

function Header({ name, menuCollapsed, setMenuCollapsed }) {
  return (
    <div className="header">
      <div>
        <Button
          type="primary"
          icon={<MenuFoldOutlined />}
          size={32}
          onClick={() => {
            console.log("menuCollapsed", menuCollapsed);
            setMenuCollapsed(!menuCollapsed);
          }}
        />
      </div>
      <div>
        <h1 style={{ marginLeft: "2rem", color: "#01027C" }}>
          LECHER<span style={{ color: "#01AD51" }}>app</span>
        </h1>
      </div>
    </div>
  );
}

export default Header;
