import { useState } from "react";
import { Menu } from "antd";
import logo from "../../assets/images/ADM-Logo-Primary-300x225.webp";

import {
  //PieChartOutlined,
  //AppstoreOutlined,
  LogoutOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import * as actions from "../../actions/user";
import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { AsyncStorage } from "AsyncStorage";

const getItem = (label, key, icon, children) => {
  return {
    key,
    icon,
    children,
    label,
  };
};

const items = [
  getItem("Inicio", "1", <HomeOutlined />),
  //getItem('Dashboard', '2', <AppstoreOutlined />),
  //getItem('Usuarios', '3', <PieChartOutlined />),
  //getItem('Registros de sistema', '4', <BarsOutlined />),
  //getItem('Reglas', '5', <ScheduleOutlined />),
  //getItem('Preguntas', '6', <FunnelPlotOutlined />),
  //getItem('Promociones', '7', <CrownOutlined />),
  //getItem('Recomendaciones', '8', <CommentOutlined />),
  getItem("Cerrar sesión", "9", <LogoutOutlined />),
];

function Sidenav(props) {
  const [current, setCurrent] = useState("1");
  const navigate = useNavigate();

  const onClick = (e) => {
    setCurrent(e.key);

    if (e.key === "1") {
      navigate("/");
    } /*
    else if(e.key==='2'){
      navigate('/dashboard');
    }
    else if(e.key==='3'){
      navigate('/dashboard');
    }*/ else if (e.key === "9") {
      console.log("user => ", props.user);

      props.logOut({ username: props.user.username }).then((r) => {
        if (r.type === "FETCHING_USER_DATA_SUCCESS") {
          AsyncStorage.setItem("profile", JSON.stringify({}));
          props.setUser({});
        }
      });
      navigate("/login");
    }
  };

  return (
    <>
      <div className="brand">
        <img
          src={logo}
          alt=""
          style={{
            maxWidth: "50%",
            marginLeft: "25%",
            marginTop: 25,
            marginBottom: 15,
          }}
        />
      </div>
      <Menu
        theme="dark"
        onClick={onClick}
        selectedKeys={[current]}
        items={items}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: (data) => dispatch(actions.logOut(data)),
    setUser: (data) => dispatch(actions.setUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
