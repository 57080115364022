import {
    API_URL,
} from "../constants/endpoints";
  

const API = async (method, request) => {
    console.log('method',  method);
    console.log('request', request);

    if (method === 'loginUser') {
        try {
          const response = await fetch(`${API_URL}user/login-user/?username=${request.username}&password=${request.password}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            cache: 'default',
            credentials: 'include'
          });
          return await Promise.all([response, response.json()]);
        } catch (error) {
          console.log(error);
        }
    }
    if (method === 'logOut') {
      try {
        const response = await fetch(`${API_URL}user/logout-user/?username=${request.username}`, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          cache: 'default',
          credentials: 'include'
        });
        return await Promise.all([response, response.json()]);
      } catch (error) {
        console.log(error);
      }
    }
    if (method === 'logAction') {
      try {
        const response = await fetch(`${API_URL}log-action/`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          cache: 'default',
          credentials: 'include',
          body: JSON.stringify(request)
        });
        return await Promise.all([response, response.json()]);
      } catch (error) {
        console.log(error);
      }
    }
    else if (method === 'usersList') {
        const response = await fetch(`${API_URL}usuarios/`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            cache: 'default',
        });
        return await Promise.all([response, response.json()]);
    }
    else {
        return null;
    }
};

export default API;