import { Badge, Card, Col, Divider, Row, Space, Typography } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined } from "@ant-design/icons";

//import { dataEvaluation } from "./evaluation-by-stage-data";
const { Text, Paragraph, Title } = Typography;

export const EvaluacionPorEtapa = ({ etapas, className }) => {

	const stages = [
		{
			name: 'Muy deficiente',
			color: '#0000ff'
		},
		{
			name: 'Deficiente',
			color: 'yellow'
		},
		{
			name: 'Óptimo',
			color: 'green'
		},
		{
			name: 'Excedido',
			color: 'orange'
		},
		{
			name: 'Muy excedido',
			color: 'red'
		}
	];

	return (
		<Row className={className}>
			<Col style={{ marginBottom: 20 }}>
				<Space wrap>
					{stages.map(stage => (
						<Badge key={stage.color} color={stage.color} text={stage.name} />
					))}
				</Space>
			</Col>

			{etapas.map((item) => {
				return (
					<Col span={24} key={item.id}>
						<Divider>{item.name}</Divider>
						<Col className="col-section" span={24}>
							<Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'center' }}>

								<Col xs={24} md={8} lg={4}>
									<Card bodyStyle={{ padding: 20 }}>
										<Text>Matería seca</Text>
										<Paragraph style={{
											color: item._kg_materia_seca === 'Muy deficiente' ? '#0000ff' :
												item._kg_materia_seca === 'Deficiente' ? '#fadb14' :
													item._kg_materia_seca === 'Óptimo' ? '#52c41a' :
														item._kg_materia_seca === 'Excedido' ? '#fa8c16' : '#f5222d'
										}} strong>
											{
												item._kg_materia_seca === 'Muy deficiente' ? 'aumentar' :
													item._kg_materia_seca === 'Deficiente' ? 'aumentar' :
														item._kg_materia_seca === 'Óptimo' ? 'mantener' :
															item._kg_materia_seca === 'Excedido' ? 'disminuir' : 'disminuir'
											}
										</Paragraph>
										<Title style={{
											color: item._kg_materia_seca === 'Muy deficiente' ? '#0000ff' :
												item._kg_materia_seca === 'Deficiente' ? '#fadb14' :
													item._kg_materia_seca === 'Óptimo' ? '#52c41a' :
														item._kg_materia_seca === 'Excedido' ? '#fa8c16' : '#f5222d',
											marginBlock: 0
										}}>
											{
												item._kg_materia_seca === 'Muy deficiente' ? <ArrowUpOutlined /> :
													item._kg_materia_seca === 'Deficiente' ? <ArrowUpOutlined /> :
														item._kg_materia_seca === 'Óptimo' ? <CheckOutlined /> :
															item._kg_materia_seca === 'Excedido' ? <ArrowDownOutlined /> : <ArrowDownOutlined />
											}
											{/*
												 item._kg_materia_seca === 'Muy deficiente' ? (item.aux['materia_seca'][2] - item.materia_seca).toFixed(1) : 
												 item._kg_materia_seca === 'Deficiente' ? (item.aux['materia_seca'][2] - item.materia_seca).toFixed(1) : 
												 item._kg_materia_seca === 'Óptimo' ? (item.aux['materia_seca'][2]).toFixed(1) : 
												 item._kg_materia_seca === 'Excedido' ? (item.materia_seca - item.aux['materia_seca'][2]).toFixed(1) : (item.materia_seca - item.aux['materia_seca'][2]).toFixed(1)
											*/}
										</Title>
									</Card>
								</Col>

								<Col xs={24} md={8} lg={4}>
									<Card bodyStyle={{ padding: 20 }}>
										<Text>Energía</Text>
										<Paragraph style={{
											color: item._energia === 'Muy deficiente' ? '#0000ff' :
												item._energia === 'Deficiente' ? '#fadb14' :
													item._energia === 'Óptimo' ? '#52c41a' :
														item._energia === 'Excedido' ? '#fa8c16' : '#f5222d'
										}} strong>
											{
												item._energia === 'Muy deficiente' ? 'aumentar' :
													item._energia === 'Deficiente' ? 'aumentar' :
														item._energia === 'Óptimo' ? 'mantener' :
															item._energia === 'Excedido' ? 'disminuir' : 'disminuir'
											}
										</Paragraph>
										<Title style={{
											color: item._energia === 'Muy deficiente' ? '#0000ff' :
												item._energia === 'Deficiente' ? '#fadb14' :
													item._energia === 'Óptimo' ? '#52c41a' :
														item._energia === 'Excedido' ? '#fa8c16' : '#f5222d',
											marginBlock: 0
										}}>
											{
												item._energia === 'Muy deficiente' ? <ArrowUpOutlined /> :
													item._energia === 'Deficiente' ? <ArrowUpOutlined /> :
														item._energia === 'Óptimo' ? <CheckOutlined /> :
															item._energia === 'Excedido' ? <ArrowDownOutlined /> : <ArrowDownOutlined />
											}
											{/*
												 item._energia === 'Muy deficiente' ? (item.aux['energia'][2] - item.energia).toFixed(1) : 
												 item._energia === 'Deficiente' ? (item.aux['energia'][2] - item.energia).toFixed(1) : 
												 item._energia === 'Óptimo' ? (item.aux['energia'][2]).toFixed(1) : 
												 item._energia === 'Excedido' ? (item.energia - item.aux['energia'][2]).toFixed(1) : (item.energia - item.aux['energia'][2]).toFixed(1)
											*/}
										</Title>
									</Card>
								</Col>

								<Col xs={24} md={8} lg={4}>
									<Card bodyStyle={{ padding: 20 }}>
										<Text>Forraje</Text>
										<Paragraph style={{
											color: item._forraje === 'Muy deficiente' ? '#0000ff' :
												item._forraje === 'Deficiente' ? '#fadb14' :
													item._forraje === 'Óptimo' ? '#52c41a' :
														item._forraje === 'Excedido' ? '#fa8c16' : '#f5222d'
										}} strong>
											{
												item._forraje === 'Muy deficiente' ? 'aumentar' :
													item._forraje === 'Deficiente' ? 'aumentar' :
														item._forraje === 'Óptimo' ? 'mantener' :
															item._forraje === 'Excedido' ? 'disminuir' : 'disminuir'
											}
										</Paragraph>
										<Title style={{
											color: item._forraje === 'Muy deficiente' ? '#0000ff' :
												item._forraje === 'Deficiente' ? '#fadb14' :
													item._forraje === 'Óptimo' ? '#52c41a' :
														item._forraje === 'Excedido' ? '#fa8c16' : '#f5222d',
											marginBlock: 0
										}}>
											{
												item._forraje === 'Muy deficiente' ? <ArrowUpOutlined /> :
													item._forraje === 'Deficiente' ? <ArrowUpOutlined /> :
														item._forraje === 'Óptimo' ? <CheckOutlined /> :
															item._forraje === 'Excedido' ? <ArrowDownOutlined /> : <ArrowDownOutlined />
											}
											{/*
												 item._forraje === 'Muy deficiente' ? (item.aux['forraje'][2] - item.forraje).toFixed(1) : 
												 item._forraje === 'Deficiente' ? (item.aux['forraje'][2] - item.forraje).toFixed(1) : 
												 item._forraje === 'Óptimo' ? (item.aux['forraje'][2]).toFixed(1) : 
												 item._forraje === 'Excedido' ? (item.forraje - item.aux['forraje'][2]).toFixed(1) : (item.forraje - item.aux['forraje'][2]).toFixed(1)
											*/}
										</Title>
									</Card>
								</Col>

								<Col xs={24} md={8} lg={4}>
									<Card bodyStyle={{ padding: 20 }}>
										<Text>Proteina</Text>
										<Paragraph style={{
											color: item._proteina === 'Muy deficiente' ? '#0000ff' :
												item._proteina === 'Deficiente' ? '#fadb14' :
													item._proteina === 'Óptimo' ? '#52c41a' :
														item._proteina === 'Excedido' ? '#fa8c16' : '#f5222d'
										}} strong>
											{
												item._proteina === 'Muy deficiente' ? 'aumentar' :
													item._proteina === 'Deficiente' ? 'aumentar' :
														item._proteina === 'Óptimo' ? 'mantener' :
															item._proteina === 'Excedido' ? 'disminuir' : 'disminuir'
											}
										</Paragraph>
										<Title style={{
											color: item._proteina === 'Muy deficiente' ? '#0000ff' :
												item._proteina === 'Deficiente' ? '#fadb14' :
													item._proteina === 'Óptimo' ? '#52c41a' :
														item._proteina === 'Excedido' ? '#fa8c16' : '#f5222d',
											marginBlock: 0
										}}>
											{
												item._proteina === 'Muy deficiente' ? <ArrowUpOutlined /> :
													item._proteina === 'Deficiente' ? <ArrowUpOutlined /> :
														item._proteina === 'Óptimo' ? <CheckOutlined /> :
															item._proteina === 'Excedido' ? <ArrowDownOutlined /> : <ArrowDownOutlined />
											}
											{/*
												 item._proteina === 'Muy deficiente' ? (item.aux['proteina'][2] - item.proteina).toFixed(1) : 
												 item._proteina === 'Deficiente' ? (item.aux['proteina'][2] - item.proteina).toFixed(1) : 
												 item._proteina === 'Óptimo' ? (item.aux['proteina'][2]).toFixed(1) : 
												 item._proteina === 'Excedido' ? (item.proteina - item.aux['proteina'][2]).toFixed(1) : (item.proteina - item.aux['proteina'][2]).toFixed(1)
											*/}
										</Title>
									</Card>
								</Col>

								<Col xs={24} md={8} lg={4}>
									<Card bodyStyle={{ padding: 20 }}>
										<Text>Humedad</Text>
										<Paragraph style={{
											color: item._humedad === 'Muy deficiente' ? '#0000ff' :
												item._humedad === 'Deficiente' ? '#fadb14' :
													item._humedad === 'Óptimo' ? '#52c41a' :
														item._humedad === 'Excedido' ? '#fa8c16' : '#f5222d'
										}} strong>
											{
												item._humedad === 'Muy deficiente' ? 'aumentar' :
													item._humedad === 'Deficiente' ? 'aumentar' :
														item._humedad === 'Óptimo' ? 'mantener' :
															item._humedad === 'Excedido' ? 'disminuir' : 'disminuir'
											}
										</Paragraph>
										<Title style={{
											color: item._humedad === 'Muy deficiente' ? '#0000ff' :
												item._humedad === 'Deficiente' ? '#fadb14' :
													item._humedad === 'Óptimo' ? '#52c41a' :
														item._humedad === 'Excedido' ? '#fa8c16' : '#f5222d',
											marginBlock: 0
										}}>
											{
												item._humedad === 'Muy deficiente' ? <ArrowUpOutlined /> :
													item._humedad === 'Deficiente' ? <ArrowUpOutlined /> :
														item._humedad === 'Óptimo' ? <CheckOutlined /> :
															item._humedad === 'Excedido' ? <ArrowDownOutlined /> : <ArrowDownOutlined />
											}
											{/*
												 item._humedad === 'Muy deficiente' ? (item.aux['humedad'][2] - item.humedad).toFixed(1) : 
												 item._humedad === 'Deficiente' ? (item.aux['humedad'][2] - item.humedad).toFixed(1) : 
												 item._humedad === 'Óptimo' ? (item.aux['humedad'][2]).toFixed(1) : 
												 item._humedad === 'Excedido' ? (item.humedad - item.aux['humedad'][2]).toFixed(1) : (item.humedad - item.aux['humedad'][2]).toFixed(1)
											*/}
										</Title>
									</Card>
								</Col>

							</Row>
						</Col>
					</Col>
				)
			})}

		</Row>
	)
}