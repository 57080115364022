import {
  List,
  Popover,
  Card,
  Row,
  Col,
  Typography
} from "antd";
const { Title, Paragraph } = Typography;

const Banner = ({ item }) => {
  return (
    <Card>
      <Row className="text-center">
        {item.image && (
          <Col span={24}>
            <img src={item.image.full_url} style={{ maxWidth: '100%' }} alt={item.title} />
          </Col>
        )}
        <Col span={24}>
          <Paragraph style={{ marginBlock: '1em' }}>{item.desc}</Paragraph>
        </Col>
        <Col span={24}>
          {item.documentos && item.documentos.length > 0 && (
            item.documentos.map((document) => {
              return (
                <a key={document.id} target="_blank" href={document.doc.meta.download_url}>{document.doc.title}</a>
              )
            })
          )}
        </Col>
      </Row>
    </Card>
  )
}

export const GeneralRecommendations = ({ recomendationsList }) => (
  <List
    size="large"
    bordered
    dataSource={Array.from(recomendationsList)}
    renderItem={(item) => (<Popover style={{ width: 200 }} title={item.name} content={<Banner item={item} />}>
      <List.Item>{item.name}</List.Item>
    </Popover>)}
  />
)
