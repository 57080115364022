import {
    FETCHING_USER_DATA,
    FETCHING_USER_DATA_SUCCESS,
    FETCHING_USER_DATA_FAILURE,
} from '../constants/actionTypes.js';

//import {API_URL,} from "../constants/endpoints";
import api from '../api/user';
  
export const loginUser = (data) => {
    return async (dispatch) => {
        dispatch(getData(data));
        try {
            const [response, json] = await api('loginUser', data);
            json.statusCode = response.status;
            return dispatch(getDataSuccess(json));
        } catch (err) {
            console.log(err);
            return getDataFailure();
        }
    };
};
export const logOut = (data) => {
    return async (dispatch) => {
        dispatch(getData(data));
        try {
            const [response, json] = await api('logOut', data);
            json.statusCode = response.status;
            return dispatch(getDataSuccess(json));
        } catch (err) {
            console.log(err);
            return getDataFailure();
        }
    };
};
export const logAction = (data) => {
    return async (dispatch) => {
        dispatch(getData(data));
        try {
            const [response, json] = await api('logAction', data);
            json.statusCode = response.status;
            return dispatch(getDataSuccess(json));
        } catch (err) {
            console.log(err);
            return getDataFailure();
        }
    };
};

export const usersList = (data) => {
    return async (dispatch) => {
        dispatch(getData(data));
        try {
            const [response, json] = await api('usersList', data);
            json.statusCode = response.status;
            return dispatch(getDataSuccess(json));
        } catch (err) {
            console.log(err);
            return getDataFailure();
        }
    };
};



export const setUser = (user) => {
    //console.log('en setUser', user);
    return {
        type: 'SET_USER',
        payload: user
    }
}

//API Requests
const getData = (data) => {
    return {
        type: FETCHING_USER_DATA,
        payload: data,
        /*meta:{
            offline: {
                effect:   { url: `${API_URL}?r=service-usuarios-app/info-usuario-app`, method: 'POST', body: `user=${data.username}&pass=${data.pin}` },
                commit:   { type: 'FETCHING_USER_DATA_SUCCESS', meta: { data } },
                rollback: { type: 'FETCHING_USER_DATA_FAILURE', meta: { data } }
            }
        }*/
    };
};
const getDataSuccess = (data) => {
    return {
        type: FETCHING_USER_DATA_SUCCESS,
        data,
    };
};
const getDataFailure = () => {
    return {
        type: FETCHING_USER_DATA_FAILURE,
    };
};

