import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import EditableTable from "../components/editableTable/editableTable";
import {
  Row,
  Col,
  Layout,
  Button,
  Typography,
  Card,
  Space,
  Popconfirm,
  Input,
  Table
} from "antd";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { AsyncStorage } from "AsyncStorage";

import * as pages_actions from "../actions/pages";
import * as actions from "../actions/user";
import * as eactions from "../actions/exercises";

const { Title, Paragraph } = Typography;

const UNewTest = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState({});

  const [columnsForrajeSource, setColumnsForrajeSource] = useState([]);
  const [dataForrajeSource, setDataForrajeSource] = useState([]);

  const [columnsIngredienteSource, setColumnsIngredienteSource] = useState([]);
  const [dataIngredienteSource, setDataIngredienteSource] = useState([]);

  const [columnsProductoSource, setColumnsProductoSource] = useState([]);
  const [dataProductoSource, setDataProductoSource] = useState([]);

  const [columnsAguaSource, setColumnsAguaSource] = useState([]);
  const [dataAguaSource, setDataAguaSource] = useState([]);

  const [dataSummarySource, setDataSummarySource] = useState([]);

  const [shortSummaryColumns, setShortSummaryColumns] = useState([]);
  const [shortSummarySource, setShortSummarySource] = useState([]);

  const setDataForrajeSourceAdvanced = async (data) => {
    await setDataForrajeSource(data)
  }


  const onCancel = () => {
    navigate("/");
  };
  const onNext = async () => {
    console.log({
      dataForrajeSource,
      dataIngredienteSource,
      dataProductoSource,
      dataAguaSource,

      rancho: location.state.rancho,
      etapas: location.state.etapas,
      forrajes: location.state.forrajes,
      ingredientes: location.state.ingredientes,
      productos: location.state.productos,
      region: location.state.region,

      selectedEtapas: location.state.selectedEtapas,
      selectedForrajes: location.state.selectedForrajes,
      selectedIngredientes: location.state.selectedIngredientes,
      selectedProductos: location.state.selectedProductos,
    });

    const etapas = location.state.etapas.filter(
      (item) => location.state.selectedEtapas.indexOf(item.id) !== -1
    );
    const productos = location.state.productos.filter(
      (item) =>
        location.state.selectedProductos.indexOf(item.producto.id) !== -1
    );
    const ingredientes = location.state.ingredientes.filter(
      (item) =>
        location.state.selectedIngredientes.indexOf(item.ingrediente.id) !== -1
    );
    const forrajes = location.state.forrajes.filter(
      (item) => location.state.selectedForrajes.indexOf(item.forraje.id) !== -1
    );

    const mainTestData = {
      name: `${location.state.rancho.nombre} ${new Date().toLocaleString()}`,
      etapas: [],
      forrajes: [],
      ingredientes: [],
      productos: [],
      agua: [],
      rancho: location.state.rancho,
      region: location.state.region,
    };

    etapas.forEach((etapa) => {
      mainTestData.etapas.push({
        etapa: etapa.id,
        nvacas: parseInt(etapa.units),
      });
    });
    dataAguaSource.forEach((agua) => {
      mainTestData.agua = Object.keys(agua)
        .filter((key) => key !== "agua" && key !== "key")
        .map((item) => {
          const aux = item.split("_");
          return {
            etapa: parseInt(aux[1]),
            cantidad: agua[item],
          };
        });
    });
    dataForrajeSource.forEach((forraje) => {
      Object.keys(forraje)
        .filter((key) => key !== "forraje" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          const current = forrajes.filter(
            (item) => item.forraje.id === forraje.key
          )[0];
          mainTestData.forrajes.push({
            forraje: forraje.key,
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(forraje[item]),

            precio: parseFloat(current.price),
            almd: parseFloat(current.almd),
            ms: parseFloat(current.ms),
            pc: parseFloat(current.pc),
          });
        });
    });
    dataIngredienteSource.forEach((ingrediente) => {
      Object.keys(ingrediente)
        .filter((key) => key !== "ingrediente" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          const current = ingredientes.filter(
            (item) => item.ingrediente.id === ingrediente.key
          )[0];
          mainTestData.ingredientes.push({
            ingrediente: ingrediente.key,
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(ingrediente[item]),

            precio: parseFloat(current.price),
            almd: parseFloat(current.almd),
            ms: parseFloat(current.ms),
            pc: parseFloat(current.pc),
          });
        });
    });
    dataProductoSource.forEach((producto) => {
      Object.keys(producto)
        .filter((key) => key !== "producto" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          const current = productos.filter(
            (item) => item.producto.id === producto.key
          )[0];
          mainTestData.productos.push({
            producto: producto.key,
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(producto[item]),

            precio: parseFloat(current.price),
            almd: parseFloat(current.almd),
            ms: parseFloat(current.ms),
            pc: parseFloat(current.pc),
            urea: parseFloat(current.urea),
          });
        });
    });

    console.log("mainTestData", mainTestData);

    mainTestData.rancho = location.state.rancho;
    mainTestData.region = location.state.region;
    mainTestData.owner = props.user.user_id;

    await props.logAction({
      kind: "button",
      title: "Siguiente",
      description: 'El usuario presiona el botón "Siguiente" en step 1 ',
      date: new Date().valueOf(),
      user: props.user.username,
    });


    const startTest = await props.startTest(mainTestData);
    if (startTest.type === "FETCHING_EXERCISE_DATA_SUCCESS") {
      console.log(startTest);

      await props.logAction({
        kind: "save",
        title: "Ejercicio creado",
        description: "El sistema asigno el Id " + startTest.data.ejercicio,
        date: new Date().valueOf(),
        user: props.user.username,
      });

      navigate("/asuser/" + id + "/results/" + startTest.data.ejercicio, {
        state: null,
      });
    } else {
      if (!window.navigator.onLine) {

        let pendingTest = await AsyncStorage.getItem("pendingTest");
        if (pendingTest) {
          pendingTest = JSON.parse(pendingTest);
        } else {
          pendingTest = [];
        }
        pendingTest.push(mainTestData);
        AsyncStorage.setItem("pendingTest", JSON.stringify(pendingTest));

        navigate("/asuser/" + id + "/results/" + 0, {
          state: null,
        });
      }
      console.log("Error");
      console.log(startTest);
    }

  };

  useEffect(() => {
    updateSummary()
    // eslint-disable-next-line 
  }, [dataForrajeSource, dataIngredienteSource, dataProductoSource, dataAguaSource])


  const columnsSummarySource = [
    {
      title: 'Etapa',
      dataIndex: 'etapa',
      key: 'etapa',
      render: (value, record, idx) => value.toString().split(',').map(item =>
        <h5 key={'etapa' + item}>{item}</h5>
      )
    },
    {
      title: 'Forraje',
      dataIndex: 'forrajes',
      key: 'forrajes',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'forrajes_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Kg</h5 >
        )
      })
    },
    {
      title: 'Ingredientes',
      dataIndex: 'ingredientes',
      key: 'ingredientes',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'ingredientes_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Kg</h5 >
        )
      })
    },
    {
      title: 'Productos',
      dataIndex: 'productos',
      key: 'productos',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'productos_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Kg</h5 >
        )
      })
    },
    {
      title: 'Agua',
      dataIndex: 'agua',
      key: 'agua',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'agua_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Lt</h5 >
        )
      })
    },
  ]

  const groupsum = (array) => {
    var aux = []
    array.reduce(function (res, value) {
      if (!res[value.etapa]) {
        res[value.etapa] = { etapa: value.etapa, cantidad: 0 };
        aux.push(res[value.etapa])
      }
      res[value.etapa].cantidad += value.cantidad;
      return res;
    }, {});
    return aux;
  }

  const updateSummary = () => {

    const etapas = location.state.etapas.filter(
      (item) => location.state.selectedEtapas.indexOf(item.id) !== -1
    );

    const summary = {
      etapas: [],
      forrajes: [],
      ingredientes: [],
      productos: [],
      agua: [],
    };

    dataAguaSource.forEach((agua) => {
      summary.agua = Object.keys(agua)
        .filter((key) => key !== "agua" && key !== "key")
        .map((item) => {
          const aux = item.split("_");
          return {
            etapa: parseInt(aux[1]),
            cantidad: agua[item],
          };
        });
    });
    dataForrajeSource.forEach((forraje) => {
      Object.keys(forraje)
        .filter((key) => key !== "forraje" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          summary.forrajes.push({
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(forraje[item]),
          });
        });
    });
    dataIngredienteSource.forEach((ingrediente) => {
      Object.keys(ingrediente)
        .filter((key) => key !== "ingrediente" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          summary.ingredientes.push({
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(ingrediente[item]),
          });
        });
    });
    dataProductoSource.forEach((producto) => {
      Object.keys(producto)
        .filter((key) => key !== "producto" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          summary.productos.push({
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(producto[item]),
          });
        });
    });

    //console.log('dataForrajeSource', dataForrajeSource);
    if (shortSummarySource.length > 0) {
      etapas.forEach((etapa) => {
        shortSummarySource[0][`summary_${etapa.id}`] =
          summary.productos.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0) +
          summary.forrajes.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0) +
          summary.ingredientes.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0) +
          summary.agua.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)
      })
    }

    setDataSummarySource([{
      key: 1,
      etapa: [...etapas.map(etapa => `${etapa.name}`), 'TOTAL'],
      forrajes: [...groupsum(summary.forrajes).map(forraje => `${forraje.cantidad}`), summary.forrajes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //forrajes: summary.forrajes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
      ingredientes: [...groupsum(summary.ingredientes).map(ingrediente => `${ingrediente.cantidad}`), summary.ingredientes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //ingredientes: summary.ingredientes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
      productos: [...groupsum(summary.productos).map(producto => `${producto.cantidad}`), summary.productos.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //productos: summary.productos.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
      agua: [...groupsum(summary.agua).map(agua => `${agua.cantidad}`), summary.agua.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //agua: summary.agua.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
    }])



    //console.log('dataForrajeSource', dataForrajeSource);
    //console.log('updateSummary', summary)
  }

  const getAsyncProfile = async () => {
    const data = await AsyncStorage.getItem("profile");
    if (typeof data === "undefined") {
      return {};
    }
    return JSON.parse(data);
  };
  useEffect(() => {
    if (!props.user || !props.user.authenticated) {
      getAsyncProfile().then((r) => {
        console.log("asyncProfile", r);
        if (!r || !r.authenticated) {
          navigate("/login");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("location", location);
    const etapas = location.state.etapas.filter(
      (item) => location.state.selectedEtapas.indexOf(item.id) !== -1
    );
    const productos = location.state.productos.filter(
      (item) =>
        location.state.selectedProductos.indexOf(item.producto.id) !== -1
    );
    const ingredientes = location.state.ingredientes.filter(
      (item) =>
        location.state.selectedIngredientes.indexOf(item.ingrediente.id) !== -1
    );
    const forrajes = location.state.forrajes.filter(
      (item) => location.state.selectedForrajes.indexOf(item.forraje.id) !== -1
    );

    let ForrajeSource = [];
    let IngredienteSource = [];
    let ProductoSource = [];
    let AguaSource = [];
    let shortSummarySource = []

    let ForrajeColumns = [
      {
        title: "Forraje",
        dataIndex: "forraje",
        editable: false,
        fixed: window.screen.width > 640 ? "left" : false,
        width: 150,
      },
    ];
    let IngredienteColumns = [
      {
        title: "Ingrediente",
        dataIndex: "ingrediente",
        editable: false,
        fixed: window.screen.width > 640 ? "left" : false,
        width: 150,
      },
    ];
    let ProductoColumns = [
      {
        title: "Producto",
        dataIndex: "producto",
        editable: false,
        fixed: window.screen.width > 640 ? "left" : false,
        width: 150,
      },
    ];
    let AguaColumns = [
      {
        title: "Agua",
        dataIndex: "agua",
        editable: false,
        fixed: window.screen.width > 640 ? "left" : false,
        width: 150,
      },
    ];
    let shortSummaryColumns = [
      {
        title: "Resumen de dieta",
        dataIndex: "summary",
        editable: false,
        fixed: window.screen.width > 640 ? "left" : false,
        width: 150,
      },
    ]

    forrajes.forEach((forraje) => {
      ForrajeSource.push({
        key: forraje.forraje.id,
        forraje: forraje.forraje.name,
      });
    });
    ingredientes.forEach((ingrediente) => {
      IngredienteSource.push({
        key: ingrediente.ingrediente.id,
        ingrediente: ingrediente.ingrediente.name,
      });
    });
    productos.forEach((producto) => {
      ProductoSource.push({
        key: producto.producto.id,
        producto: producto.producto.name,
      });
    });

    const id_agua = location.state.ingredientes.filter(value => value.ingrediente.name.toUpperCase() === 'AGUA')[0].ingrediente.id;
    AguaSource.push({
      key: id_agua,
      agua: "Litros en dieta",
    });
    shortSummarySource.push({
      key: 'short_summary',
      summary: "Kg",
    });

    etapas.forEach((etapa) => {
      ForrajeColumns.push({
        title: etapa.name,
        dataIndex: `forraje_${etapa.id}`,
        editable: true,
      });
      IngredienteColumns.push({
        title: etapa.name,
        dataIndex: `ingrediente_${etapa.id}`,
        editable: true,
      });
      ProductoColumns.push({
        title: etapa.name,
        dataIndex: `producto_${etapa.id}`,
        editable: true,
      });
      AguaColumns.push({
        title: etapa.name,
        dataIndex: `agua_${etapa.id}`,
        editable: true,
      });
      shortSummaryColumns.push({
        title: etapa.name,
        dataIndex: `summary_${etapa.id}`,
        editable: false,
      });

      ForrajeSource.forEach((data) => {
        data[`forraje_${etapa.id}`] = 0;
      });
      IngredienteSource.forEach((data) => {
        data[`ingrediente_${etapa.id}`] = 0;
      });
      ProductoSource.forEach((data) => {
        data[`producto_${etapa.id}`] = 0;
      });
      AguaSource.forEach((data) => {
        data[`agua_${etapa.id}`] = 0;
      });
      shortSummarySource.forEach((data) => {
        data[`summary_${etapa.id}`] = 0;
      });
    });

    setColumnsForrajeSource(ForrajeColumns);
    setDataForrajeSource(ForrajeSource);

    setColumnsIngredienteSource(IngredienteColumns);
    setDataIngredienteSource(IngredienteSource);

    setColumnsProductoSource(ProductoColumns);
    setDataProductoSource(ProductoSource);

    setColumnsAguaSource(AguaColumns);
    setDataAguaSource(AguaSource);

    setShortSummaryColumns(shortSummaryColumns);
    setShortSummarySource(shortSummarySource);

    console.log('shortSummarySource', shortSummarySource)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPage = async () => {
      let page = await props.requestStep1Page();
      if (page.type === "FETCHING_PAGES_DATA_SUCCESS") {
        AsyncStorage.setItem("step1", JSON.stringify(page.data.items[0]));
        setPage(page.data.items[0]);
      } else {
        if (!window.navigator.onLine) {
          let page = await AsyncStorage.getItem("step1");
          if (page) {
            page = JSON.parse(page);
            setPage(page);
          }
        }
      }
    };
    getPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Row gutter={{ xs: 8, sm: 14 }}>
        <Col span={23}>
          <Paragraph style={{ margin: 0, textAlign: "end" }}>
            Ingresaste como {props.user.first_name}
          </Paragraph>
          <Paragraph style={{ textAlign: "end" }} strong type="success">
            {props.user.email} {/*Tangamandapio, Mich.*/}
          </Paragraph>
        </Col>
        <Col span={1} style={{ marginBlockStart: 5 }}>
          <UserOutlined className="icon-user-logged" />
        </Col>
      </Row>
      <Title style={{ margin: 0 }} type="success">
        {page.intro_title}
      </Title>
      <div
        className="info_paragraph"
        dangerouslySetInnerHTML={{
          __html: page ? page.intro_paragraph : "",
        }}
      ></div>

      <Card style={{ marginTop: 20, padding: 40 }}>
        <Col xs={0} md={24}>
          <strong>Información del Rancho</strong>
          <Paragraph style={{ margin: 0, textAlign: "start" }}>
            A continuación la información de el Raancho para el que vamos a
            realizar este test.
          </Paragraph>
          <Row style={{ marginTop: 10 }} className="rancho-data">
            <Input
              addonBefore="Nombre del Rancho"
              readOnly={true}
              type="text"
              value={location.state.rancho.nombre}
            />
            <Input
              addonBefore="Dirección del Rancho"
              readOnly={true}
              type="text"
              value={location.state.rancho.direccion}
            />
            <Input
              addonBefore="Teléfono de contacto"
              readOnly={true}
              type="tel"
              value={location.state.rancho.telefono}
            />
            <Input
              addonBefore="Email de contacto"
              readOnly={true}
              type="email"
              value={location.state.rancho.email}
            />
          </Row>
        </Col>
        <Col xs={24} md={0}>
          <strong>Información del Rancho</strong>
          <Paragraph style={{ margin: 0, textAlign: "start" }}>
            A continuación la información de el Raancho para el que vamos a
            realizar este test.
          </Paragraph>
          <Row style={{ marginTop: 10 }} className="rancho-data">
            <Input
              placeholder="Nombre del Rancho"
              readOnly={true}
              type="text"
              value={location.state.rancho.nombre}
            />
            <Input
              placeholder="Dirección del Rancho"
              readOnly={true}
              type="text"
              value={location.state.rancho.direccion}
            />
            <Input
              placeholder="Teléfono de contacto"
              readOnly={true}
              type="tel"
              value={location.state.rancho.telefono}
            />
            <Input
              placeholder="Email de contacto"
              readOnly={true}
              type="email"
              value={location.state.rancho.email}
            />
          </Row>
        </Col>
        <Col xs={0} md={24} style={{ marginTop: 20 }}>
          <Row>
            <Col span={8}>
              <Input
                addonBefore="Region"
                disabled={true}
                value={location.state.region.name}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={0} style={{ marginTop: 20 }}>
          <Row>
            <Col span={24}>
              <Input
                placeholder="Region"
                disabled={true}
                value={location.state.region.name}
              />
            </Col>
          </Row>
        </Col>
      </Card>

      <h2 style={{ marginTop: 35 }}>{page.data_title}</h2>
      <div
        className="info_paragraph"
        dangerouslySetInnerHTML={{
          __html: page ? page.data_paragraph : "",
        }}
      ></div>
      <Card style={{ marginTop: 20, padding: 40 }}>
        <Row>
          <Col span={24}>
            {columnsForrajeSource.length > 0 && (
              <EditableTable
                columnsOSource={columnsForrajeSource}
                dataOSource={dataForrajeSource}
                stateChanger={setDataForrajeSourceAdvanced}
              />
            )}
          </Col>
        </Row>
        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col span={24}>
            {columnsIngredienteSource.length > 0 && (
              <EditableTable
                columnsOSource={columnsIngredienteSource}
                dataOSource={dataIngredienteSource}
                stateChanger={setDataIngredienteSource}
              />
            )}
          </Col>
        </Row>
        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col span={24}>
            {columnsProductoSource.length > 0 && (
              <EditableTable
                columnsOSource={columnsProductoSource}
                dataOSource={dataProductoSource}
                stateChanger={setDataProductoSource}
              />
            )}
          </Col>
        </Row>
        <Row>
          <br />
          <br />
        </Row>

        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col span={24}>
            <Paragraph>
              Es el agua que se coloca en la mezcladora de alimentos
            </Paragraph>
          </Col>
          <Col span={24}>
            {columnsAguaSource.length > 0 && (
              <EditableTable
                columnsOSource={columnsAguaSource}
                dataOSource={dataAguaSource}
                stateChanger={setDataAguaSource}
              />
            )}
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col span={24}>
            <Paragraph>
              Aquí el resumen de la dieta
            </Paragraph>
          </Col>

          <Col span={24}>
            {shortSummaryColumns.length > 0 && (
              <EditableTable
                columnsOSource={shortSummaryColumns}
                dataOSource={shortSummarySource}
                stateChanger={setShortSummarySource}
              />
            )}
          </Col>

          <Col span={24}>
            <br />
            <Table className="readOnlyTable" dataSource={dataSummarySource} columns={columnsSummarySource} pagination={false} />
          </Col>
        </Row>
        <Row>
          <br />
          <br />
        </Row>
        <Row>
          <Col span={24}>
            <Space>
              <Button className="btn-cancel-test" ghost onClick={onCancel}>
                Cancelar
              </Button>
              <Popconfirm
                title="Guardar para su evaluación"
                description="¿Estas seguro de realizar está acción?, esto creará un registro en base de datos de forma permanente"
                onConfirm={onNext}
                okText="Sí"
                cancelText="No"
              >
                <Button type="primary" ghost>
                  Calcular
                </Button>
              </Popconfirm>
            </Space>
          </Col>
        </Row>
      </Card>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logAction: (data) => dispatch(actions.logAction(data)),
    startTest: (data) => dispatch(eactions.startTest(data)),

    requestStep1Page: (data) => dispatch(pages_actions.requestStep1Page(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UNewTest);
