//export const API_URL = 'http://localhost:8000/api/v2/';
//export const CMS_URL = 'http://localhost:8000/';

//export const API_URL = "http://143.110.153.139:1200/api/v2/";
//export const CMS_URL = "http://143.110.153.139:1200/";

//export const API_URL = "https://cms-lecherapp.nerdytrust.com/api/v2/";
//export const CMS_URL = "https://cms-lecherapp.nerdytrust.com/";

export const API_URL = "https://admin.lecherapp.apiaba.mx/api/v2/";
export const CMS_URL = "https://admin.lecherapp.apiaba.mx/";
