import {
    API_URL,
} from "../constants/endpoints";
  

const API = async (method, request) => {
    //console.log('method',  method);
    //console.log('request', request);

    if (method === 'requestProductos') {
        const response = await fetch(`${API_URL}productos_region/${request}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'default',
        });
        return await Promise.all([response, response.json()]);
    }
    else {
        return null;
    }
};

export default API;