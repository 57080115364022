import {
    API_URL,
} from "../constants/endpoints";
  

const API = async (method, request) => {
    //console.log('method',  method);
    //console.log('request', request);

    if (method === 'exercicesList') {
        const response = await fetch(`${API_URL}ejercicios/${request}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'default',
        });
        return await Promise.all([response, response.json()]);
    }
    else if ( method === 'exerciceRecord') {
        const response = await fetch(`${API_URL}ejercicios/${request}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'default',
        });
        return await Promise.all([response, response.json()]);
    }
    else if (method === 'startTest') {
        const response = await fetch(`${API_URL}iniciar-prueba/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'default',
            body: JSON.stringify(request)
            
        })
        return await Promise.all([response, response.json()])
    }
    else {
        return null;
    }
};

export default API;