import { Table, Typography } from "antd";

const currencyFormat = (num) => {
  //console.log('num',num);
  if (!num) { return '$ 0.0' }
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const columnsCostosGenerales = [{
  title: '',
  dataIndex: 'name',
  key: 'name',
},
{
  title: 'General',
  dataIndex: 'costo_general',
  key: 'general',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
},
{
  title: 'Productos',
  dataIndex: 'costo_productos',
  key: 'producto',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
},
{
  title: 'Forraje',
  dataIndex: 'costo_forraje',
  key: 'forrage',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
},
{
  title: 'Ingredientes',
  dataIndex: 'costo_ingredientes',
  key: 'ingrediente',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
}
];

const columnsCostosGeneralesInd = [{
  title: '',
  dataIndex: 'name',
  key: 'name',
},
{
  title: 'General',
  dataIndex: 'costo_individual',
  key: 'general',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
},
{
  title: 'Productos',
  dataIndex: 'costo_productos_individual',
  key: 'producto',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
},
{
  title: 'Forraje',
  dataIndex: 'costo_forrajes_individual',
  key: 'forrage',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
},
{
  title: 'Ingredientes',
  dataIndex: 'costo_ingredientes_individual',
  key: 'ingrediente',
  render: (value) => (<Typography.Text>{currencyFormat(value)}</Typography.Text>)
}
];

/*
const dataSourceCostosGenerales = [{
    key: '1',
    name: 'Vaquillas Próximas',
    general: '$200',
    products: '$10',
    forage: '$30'
  },
  {
    key: '2',
    name: 'Secas',
    general: '$200',
    products: '$40',
    forage: '$30'
  },
  {
    key: '3',
    name: 'Reto',
    general: '$100',
    products: '$40',
    forage: '$30'
  },
  {
    key: '4',
    name: 'Frescas',
    general: '$20',
    products: '$40',
    forage: '$30'
  }
];
*/

export const TableCostosGenerales = ({ etapas, individual }) => (
  <Table
    className="costos_generales"
    columns={individual ? columnsCostosGeneralesInd : columnsCostosGenerales}
    dataSource={etapas}
    pagination={{ position: ['none', 'none'] }}
    size="small"
    scroll={{
      x: 'max-content' //columns.length * 150,
    }}
  />
);