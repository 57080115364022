export const FETCHING_USER_DATA 			= 'FETCHING_USER_DATA';
export const FETCHING_USER_DATA_SUCCESS 	= 'FETCHING_USER_DATA_SUCCESS';
export const FETCHING_USER_DATA_FAILURE 	= 'FETCHING_USER_DATA_FAILURE';

export const FETCHING_PAGES_DATA 			= 'FETCHING_PAGES_DATA';
export const FETCHING_PAGES_DATA_SUCCESS 	= 'FETCHING_PAGES_DATA_SUCCESS';
export const FETCHING_PAGES_DATA_FAILURE 	= 'FETCHING_PAGES_DATA_FAILURE';

export const FETCHING_EXERCISE_DATA 			= 'FETCHING_EXERCISE_DATA';
export const FETCHING_EXERCISE_DATA_SUCCESS 	= 'FETCHING_EXERCISE_DATA_SUCCESS';
export const FETCHING_EXERCISE_DATA_FAILURE 	= 'FETCHING_EXERCISE_DATA_FAILURE';

export const FETCHING_PROMOS_DATA 			= 'FETCHING_PROMOS_DATA';
export const FETCHING_PROMOS_DATA_SUCCESS 	= 'FETCHING_PROMOS_DATA_SUCCESS';
export const FETCHING_PROMOS_DATA_FAILURE 	= 'FETCHING_PROMOS_DATA_FAILURE';

export const FETCHING_ETAPAS_DATA 			= 'FETCHING_ETAPAS_DATA';
export const FETCHING_ETAPAS_DATA_SUCCESS 	= 'FETCHING_ETAPAS_DATA_SUCCESS';
export const FETCHING_ETAPAS_DATA_FAILURE 	= 'FETCHING_ETAPAS_DATA_FAILURE';

export const FETCHING_PRODUCTOS_DATA 			= 'FETCHING_PRODUCTOS_DATA';
export const FETCHING_PRODUCTOS_DATA_SUCCESS 	= 'FETCHING_PRODUCTOS_DATA_SUCCESS';
export const FETCHING_PRODUCTOS_DATA_FAILURE 	= 'FETCHING_PRODUCTOS_DATA_FAILURE';

export const FETCHING_INGREDIENTES_DATA 			= 'FETCHING_INGREDIENTES_DATA';
export const FETCHING_INGREDIENTES_DATA_SUCCESS 	= 'FETCHING_INGREDIENTES_DATA_SUCCESS';
export const FETCHING_INGREDIENTES_DATA_FAILURE 	= 'FETCHING_INGREDIENTES_DATA_FAILURE';

export const FETCHING_FORRAJES_DATA 			= 'FETCHING_FORRAJES_DATA';
export const FETCHING_FORRAJES_DATA_SUCCESS 	= 'FETCHING_FORRAJES_DATA_SUCCESS';
export const FETCHING_FORRAJES_DATA_FAILURE 	= 'FETCHING_FORRAJES_DATA_FAILURE';

export const FETCHING_RECOMENDATIONS_DATA 			= 'FETCHING_RECOMENDATIONS_DATA';
export const FETCHING_RECOMENDATIONS_DATA_SUCCESS 	= 'FETCHING_RECOMENDATIONS_DATA_SUCCESS';
export const FETCHING_RECOMENDATIONS_DATA_FAILURE 	= 'FETCHING_RECOMENDATIONS_DATA_FAILURE';

export const FETCHING_REGIONS_DATA 			= 'FETCHING_REGIONS_DATA';
export const FETCHING_REGIONS_DATA_SUCCESS 	= 'FETCHING_REGIONS_DATA_SUCCESS';
export const FETCHING_REGIONS_DATA_FAILURE 	= 'FETCHING_REGIONS_DATA_FAILURE';

