import { API_URL } from "../constants/endpoints";

const API = async (method, request) => {
  //console.log('method',  method);
  //console.log('request', request);

  if (method === "requestHomePage") {
    const response = await fetch(
      `${API_URL}pages/?type=home.HomePage&fields=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "default",
      }
    );
    return await Promise.all([response, response.json()]);
  } else if (method === "requestStep0Page") {
    const response = await fetch(
      `${API_URL}pages/?type=steps.Step1Page&fields=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "default",
      }
    );
    return await Promise.all([response, response.json()]);
  } else if (method === "requestStep1Page") {
    const response = await fetch(
      `${API_URL}pages/?type=steps.Step2Page&fields=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "default",
      }
    );
    return await Promise.all([response, response.json()]);
  } else if (method === "requestStep3Page") {
    const response = await fetch(
      `${API_URL}pages/?type=steps.Step3Page&fields=*`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "default",
      }
    );
    return await Promise.all([response, response.json()]);
  } else {
    return null;
  }
};

export default API;
