import React from "react";
import { useState, useRef, useContext, useEffect } from 'react';

import {
    Table,
    Input,
    Form
} from "antd";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex] ? record[dataIndex] : '',
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: '100%', maxWidth: 200 }} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

const EditableTable = ({ columnsOSource, dataOSource, stateChanger, readOnly }) => {

    const handleSave = (row) => {
        const newData = [...dataOSource];
        let index = null;
        if (row.key) {
            index = newData.findIndex((item) => row.key === item.key);
        } else {
            index = newData.findIndex((item) => row.id === item.id);
        }

        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        stateChanger(newData)
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };
    const columns = columnsOSource.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: readOnly ? false : col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                width: window.innerWidth / columns.length,
                handleSave,
            }),
        };
    });

    return (
        <>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered={!readOnly}
                size={readOnly ? 'small' : 'small'}
                dataSource={dataOSource}
                columns={columns}
                scroll={!readOnly ? {
                    x: 'max-content' //columns.length * 150,
                } : false}
                pagination={{ position: ['none', 'none'] }}
            />
        </>
    );
}

export default EditableTable;