import React from 'react';
import './App.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';

import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';

import storeConfig from './store/index';
import thunk from 'redux-thunk';

import Main from './components/layout/main';
import Login from './pages/login';
import Home from './pages/home';
import EDashboard from './pages/executive.dashboard';
import UNewTest from './pages/user.newtest';
import UNewTestStep1 from './pages/user.newtest.step1';
import UResults from './pages/user.results';

const store = createStore(
  storeConfig,
  undefined,
  compose(
    applyMiddleware(thunk),
    offline(offlineConfig)
  )
);

function Fault(){
  return (<div>Fault</div>)
}

function App() {
  return (
    <Provider store={store}>
      <Router history='browserHistory'>
        <div className="wrapper">
          <Main>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/login" element={<Login/>} />
              <Route exact path="/dashboard" element={<EDashboard/>} />

              <Route exact path="/asuser/:id/newtest" element={<UNewTest/>} />
              <Route exact path="/asuser/:id/newtest/step1" element={<UNewTestStep1/>} />
              <Route exact path="/asuser/:id/results/:testId" element={<UResults />} />

              <Route exact path="*" element={<Fault/>} />
            </Routes>
          </Main>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
