import {
    FETCHING_USER_DATA,
    FETCHING_USER_DATA_SUCCESS,
    FETCHING_USER_DATA_FAILURE
  } from '../constants/actionTypes'
  
  
  const initialState = {
    isFetching: false,
    error:false
  }
  
  const user = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER':
        state = action.payload
        return {
          ...state,
      };
     
      //Inicio de casos para API
      case FETCHING_USER_DATA:
        state.isFetching = true;
        return {
            ...state,
            data: [],
        }
      case FETCHING_USER_DATA_SUCCESS:
        state.isFetching = false;
        return {
            ...state,
            data: action.data,
        }
      case FETCHING_USER_DATA_FAILURE:
        state.isFetching = false;
        return {
            ...state,
            error: true,
        }
      default:
        return state
    }
  }
  
  export default user
  
  