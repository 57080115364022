import {connect}    from 'react-redux';
import * as React from 'react';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import * as actions from '../actions/user'
import logo from "../assets/images/ADM-Logo-Primary-300x225.webp";
import { useNavigate } from 'react-router-dom';
import {AsyncStorage} from 'AsyncStorage';
import {
    Button,
    Row,
    Col,
    Form, 
    Input,
    Layout,
    Card,
    Checkbox,
    message,
    Spin
} from "antd";

const formItemLayout = {
    wrapperCol: {
      span: 24
    }
};

const Login = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const setProfileToStorage = async (profile) => {
      await AsyncStorage.setItem('profile', JSON.stringify(profile))
      setTimeout(() => {
        setLoading(false)
        navigate('/')
      }, 1000);
    }

    const onCheck = async () => {
        try {
            const values = await form.validateFields();
            console.log('Success:', values);
            setLoading(false);

            const login = await props.loginUser({ username: values.username.toLowerCase().trim(), password: values.password.trim() })
            if (login.type === 'FETCHING_USER_DATA_SUCCESS' && login.data.is_user) {
              await props.setUser(login.data)
              setProfileToStorage(login.data)
            }
            else {
              form.resetFields()
              messageApi.error('Nombre de usuario o contraseña no validos');
              setLoading(false)
            }

        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    return (
    <Layout>
      {contextHolder}

      <div style={{display: loading ? 'flex' : 'none',
            position:'fixed',
            height:'100%',
            width:'100%',
            zIndex:1000,
            background:'rgba(234,234,234,0.8)',
            justifyContent: 'center',
            alignItems:'center',
            top: 0,
            left: 0
        }}>
        <Spin spinning={true} size='large' />
      </div>

      <Row style={{ justifyContent: 'center' }}>
        <Col xs={24} md={12} lg={10}>
          <Card className="card-login">
            <Row>
              <Col span={24}>
                <img src={logo} alt="" style={{maxWidth:'50%', display: 'flex', marginInline: 'auto', marginBlock: 25}}/>
              </Col>
              <Col span={24}>  
                <Form form={form} name="dynamic_rule" style={{ padding: '25px' }}>
                  <Form.Item
                    {...formItemLayout}
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Ingresa tu usuario',
                      },
                    ]}
                  >
                      <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      name="password"
                      rules={[
                      {
                          required: true,
                          message: 'Ingresa tu contraseña',
                      },
                      ]}
                    >
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Contraseña" />
                    </Form.Item>
                    <Form.Item {...formItemLayout}>
                      <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Recordarme</Checkbox>
                      </Form.Item>

                      <a className="login-form-forgot" href="/">
                        Olvidé mi contraseña
                      </a>
                    </Form.Item>
                    <Form.Item {...formItemLayout}>
                      <Button className="btn-enter" onClick={onCheck}>
                          Entrar
                      </Button>
                    </Form.Item>
                    </Form>
                </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
      user: state.user,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      loginUser: (data) => dispatch(actions.loginUser(data)),
      setUser: (data) => dispatch(actions.setUser(data)),
    };
  };
  
export default connect(mapStateToProps,mapDispatchToProps)(Login)
