import {
    FETCHING_REGIONS_DATA,
    FETCHING_REGIONS_DATA_SUCCESS,
    FETCHING_REGIONS_DATA_FAILURE,
} from '../constants/actionTypes.js';
//import {API_URL} from "../constants/endpoints";
import api from '../api/regiones';

export const requestRegiones = (data) => {
    return async (dispatch) => {
        dispatch(getData(data));
        try {
            const [response, json] = await api('requestRegiones', data);
            json.statusCode = response.status;
            return dispatch(getDataSuccess(json));
        } catch (err) {
            console.log(err);
            return getDataFailure();
        }
    };
};


//API Requests
const getData = (data) => {
    return {
        type: FETCHING_REGIONS_DATA,
        payload: data,
    };
};
const getDataSuccess = (data) => {
    return {
        type: FETCHING_REGIONS_DATA_SUCCESS,
        data,
    };
};
const getDataFailure = () => {
    return {
        type: FETCHING_REGIONS_DATA_FAILURE,
    };
};

