import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import { ConfigProvider } from "antd";

import Sidenav from "./sidenav";
import Header from "./header";
import Footer from "./footer";
import { useState } from "react";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  let { pathname } = useLocation();
  const [menuCollapsed, setMenuCollapsed] = useState(true);

  pathname = pathname.replace("/", "");

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3CB548",
        },
      }}
    >
      <Layout className={``}>
        {pathname !== "login" && (
          <Sider
            collapsedWidth={window.screen.width >= 640 ? "80" : "0"}
            width={200}
            collapsed={menuCollapsed}
          >
            <Sidenav />
          </Sider>
        )}
        <Layout>
          {pathname !== "login" && (
            <AntHeader className={``}>
              <Header
                name={pathname}
                menuCollapsed={menuCollapsed}
                setMenuCollapsed={setMenuCollapsed}
                style={{ marginLeft: 0 }}
              />
            </AntHeader>
          )}
          <Content className="content-ant padding-content">{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

export default Main;
