import {
  Card,
  Col,
  Typography,
  Row,
  Button,
  Modal
} from "antd";
import React, { useState } from "react";
const { Title, Paragraph } = Typography;

export const Promotions = ({ promos }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setPromotion] = useState(null);

  //console.log('promos', promos);

  if (!promos) {
    return null;
  }

  const showModal = (e) => {
    console.log('e', e)
    if (e && e.id != null) {
      setPromotion(e)
      setIsModalOpen(true)
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (promos.items.length > 0) {
    return (
      <Row gutter={20}>

        {current && (
          <Modal title="" open={isModalOpen} onCancel={handleCancel} footer={null} >
            <Card style={{ background: '#f5f5f5' }} bodyStyle={{ padding: 30 }}>
              <Row className="text-center">
                <Col span={24}>
                  {current.image && (
                    <img src={current.image.full_url} style={{ maxWidth: '100%' }} alt="promotion" />
                  )}
                </Col>
                <Col span={24}>
                  <Title className="promotion-title" type="success">{current.name}</Title>
                  <Paragraph style={{ marginBlock: '1em' }}>{current.desc}</Paragraph>
                </Col>
                <Col span={24}>
                  {current.documentos && current.documentos.length > 0 && (
                    current.documentos.map((document) => {
                      return (
                        <a key={document.id} rel="noreferrer" target="_blank" href={document.doc.meta.download_url}>{document.doc.title}</a>
                      )
                    })
                  )}
                </Col>
              </Row>
            </Card>
          </Modal>
        )}

        {promos.items.map((promotion, idx) => (

          <Col xs={24} md={8} key={idx} >
            <Card style={{ background: '#f5f5f5' }} bodyStyle={{ padding: 30 }}>
              <Row className="text-center">
                <Col span={24}>
                  {promotion.image && (
                    <img src={promotion.image.full_url} style={{ maxWidth: '100%' }} alt="promotion" />
                  )}
                </Col>
                <Col span={24}>
                  <Title className="promotion-title" type="success">{promotion.name}</Title>
                  {/*<Paragraph style={{marginBlock: '1em'}}>{promotion.desc}</Paragraph>*/}
                  <Button className="btn-adm" block onClick={() => showModal(promotion)} >Ver promoción</Button>
                </Col>
              </Row>
            </Card>
          </Col>

        ))}
      </Row >
    )
  }

  return null;
}
