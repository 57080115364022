import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Row,
  Col,
  Button,
  //Progress,
  Layout,
  Typography,
  Dropdown,
  Space,
  Card,
  Anchor,
  Input,
  FloatButton,
  Modal,
  message,
  Popconfirm,
  Table,
  Badge
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { UserOutlined, DownOutlined, PrinterOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import {
  EvaluacionPorEtapa,
  GeneralRecommendations,
  TableCostosGenerales,
  //TableCostosIndividuales,
} from "../components/results";
import { Promotions } from "../components/results/promotions";

import * as actions from "../actions/user";
import * as eactions from "../actions/exercises";
import * as pactions from "../actions/promos";
import * as ractions from "../actions/recomendations";
import * as gactions from "../actions/regiones";
import * as pages_actions from "../actions/pages";

import EditableTable from "../components/editableTable/editableTable";
import { AsyncStorage } from "AsyncStorage";

function getRoute(path) {
  let args = Array.prototype.slice.call(arguments, 1);
  let count = -1;
  return path.replace(/:[a-zA-Z?]+/g, function (match) {
    count += 1;
    return args[count] !== undefined ? args[count] : match;
  });
}

const { Paragraph, Title, Text } = Typography;

//fixed
const itemsMenu = [
  {
    title: "Costos generales",
    key: "costos-generales",
    href: "#costos-generales",
  },
  /*{
    title: "Costos individuales",
    key: "costos-individuales",
    href: "#costos-individuales",
  },*/
  {
    title: "Evaluación por etapa",
    key: "evaluacion-por-etapa",
    href: "#evaluacion-por-etapa",
  },
  {
    title: "Recomendaciones generales",
    key: "recomendaciones-generales",
    href: "#recomendaciones-generales",
  },
];
const decitionData = (regiones, region) => {
  let obj = {};
  const current = regiones.filter((item) => item.id === region.id);
  //console.log('current',current)
  current[0].etapa.forEach((etapa) => {
    obj[`etapa_${etapa.etapa.id}`] = {
      materia_seca: etapa.materia_seca.split(",").map((e) => parseFloat(e)),
      proteina: etapa.proteina.split(",").map((e) => parseFloat(e)),
      energia: etapa.energia.split(",").map((e) => parseFloat(e)),
      forraje: etapa.forraje.split(",").map((e) => parseFloat(e)),
      humedad: etapa.humedad.split(",").map((e) => parseFloat(e)),
    };
  });
  return obj;
};
const currencyFormat = (num) => {
  //console.log('num',num);
  if (!num) {
    return "$ 0.0";
  }
  return (
    "$" +
    parseFloat(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};
const customFormat = (num) => {
  //console.log('num',num);
  if (!num) {
    return "$ 0";
  }
  return (
    "$" +
    parseFloat(num)
      .toFixed(0)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

const UResults = (props) => {
  let { id, testId } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [step3, setStep3] = useState(null);

  const [excercicesList, setExcerciseList] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [excercice, setExcercise] = useState([]);
  const [promos, setPromos] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [componentReady, setComponentReady] = useState(false);
  const [recomendationsList, setRecomendationsList] = useState([]);

  const [columnsForrajeSource, setColumnsForrajeSource] = useState([]);
  const [dataForrajeSource, setDataForrajeSource] = useState([]);
  const [columnsIngredienteSource, setColumnsIngredienteSource] = useState([]);
  const [dataIngredienteSource, setDataIngredienteSource] = useState([]);
  const [columnsProductoSource, setColumnsProductoSource] = useState([]);
  const [dataProductoSource, setDataProductoSource] = useState([]);
  const [columnsAguaSource, setColumnsAguaSource] = useState([]);
  const [dataAguaSource, setDataAguaSource] = useState([]);
  const [dataSummarySource, setDataSummarySource] = useState([]);

  const [shortSummaryColumns, setShortSummaryColumns] = useState([]);
  const [shortSummarySource, setShortSummarySource] = useState([]);

  const [showEditable, setShowEditable] = useState(false);
  const [openSaveAsNew, setOpenSaveAsNew] = useState(false);
  const handleCancelSaveTest = () => { setOpenSaveAsNew(false); };
  const showPopconfirmSaveAsNew = () => { setOpenSaveAsNew(true); };
  const [newName, setNewName] = useState('');
  const getCurrentAnchor = () => "#costos-generales";

  const columnsSummarySource = [
    {
      title: 'Etapa',
      dataIndex: 'etapa',
      key: 'etapa',
      render: (value, record, idx) => value.toString().split(',').map(item =>
        <h5 key={'etapa' + item}>{item}</h5>
      )
    },
    {
      title: 'Forraje',
      dataIndex: 'forrajes',
      key: 'forrajes',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'forrajes_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Kg</h5 >
        )
      })
    },
    {
      title: 'Ingredientes',
      dataIndex: 'ingredientes',
      key: 'ingredientes',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'ingredientes_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Kg</h5 >
        )
      })
    },
    {
      title: 'Productos',
      dataIndex: 'productos',
      key: 'productos',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'productos_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Kg</h5 >
        )
      })
    },
    {
      title: 'Agua',
      dataIndex: 'agua',
      key: 'agua',
      render: (value, record, idx) => value.toString().split(',').map(item => {
        return (
          <h5 key={'agua_' + record['etapa'][idx] + Math.random()} > {parseFloat(item)} Lt</h5 >
        )
      })
    },

  ]

  useEffect(() => {
    if (etapas.length > 0) {
      updateSummary()
    }
    // eslint-disable-next-line 
  }, [etapas, dataForrajeSource, dataIngredienteSource, dataProductoSource, dataAguaSource])

  useEffect(() => {
    const getStep3 = async () => {
      let step3 = await props.requestStep3Page();
      if (step3.type === "FETCHING_PAGES_DATA_SUCCESS") {
        setStep3(step3.data.items[0]);
      } else {
        if (!window.navigator.onLine) {
          let step3 = await AsyncStorage.getItem("step3");
          if (step3) {
            step3 = JSON.parse(step3);
            setStep3(step3);
          }
        }
      }
    };
    getStep3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupsum = (array) => {
    var aux = []
    array.reduce(function (res, value) {
      if (!res[value.etapa]) {
        res[value.etapa] = { etapa: value.etapa, cantidad: 0 };
        aux.push(res[value.etapa])
      }
      res[value.etapa].cantidad += value.cantidad;
      return res;
    }, {});
    return aux;
  }

  const updateSummary = () => {

    const summary = {
      etapas: [],
      forrajes: [],
      ingredientes: [],
      productos: [],
      agua: [],
    };

    let shortSummaryColumnsAux = [
      {
        title: "Resumen de dieta",
        dataIndex: "summary",
        editable: false,
        fixed: window.screen.width > 640 ? "left" : false,
        width: 150,
      },
    ]
    let shortSummarySourceAux = []
    shortSummarySourceAux.push({
      key: 'short_summary',
      summary: "Kg",
    });

    dataAguaSource.forEach((agua) => {
      summary.agua = Object.keys(agua)
        .filter((key) => key !== "agua" && key !== "key")
        .map((item) => {
          const aux = item.split("_");
          return {
            etapa: parseInt(aux[1]),
            cantidad: agua[item],
          };
        });
    });
    dataForrajeSource.forEach((forraje) => {
      Object.keys(forraje)
        .filter((key) => key !== "forraje" && key !== "key" && key !== "precio")
        .forEach((item) => {
          const aux = item.split("_");
          summary.forrajes.push({
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(forraje[item]),
          });
        });
    });
    dataIngredienteSource.forEach((ingrediente) => {
      Object.keys(ingrediente)
        .filter((key) => key !== "ingrediente" && key !== "key" && key !== "precio")
        .forEach((item) => {
          const aux = item.split("_");
          summary.ingredientes.push({
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(ingrediente[item]),
          });
        });
    });
    dataProductoSource.forEach((producto) => {
      Object.keys(producto)
        .filter((key) => key !== "producto" && key !== "key" && key !== "precio")
        .forEach((item) => {
          const aux = item.split("_");
          summary.productos.push({
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(producto[item]),
          });
        });
    });

    if (shortSummarySourceAux.length > 0) {
      etapas.forEach((etapa) => {
        shortSummaryColumnsAux.push({
          title: etapa.name,
          dataIndex: `summary_${etapa.id}`,
          editable: true,
        });

        shortSummarySourceAux[0][`summary_${etapa.id}`] =
          summary.productos.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0) +
          summary.forrajes.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0) +
          summary.ingredientes.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0) +
          summary.agua.filter(item => item.etapa === etapa.id).map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)
      })
    }

    setShortSummaryColumns(shortSummaryColumnsAux)
    setShortSummarySource(shortSummarySourceAux);

    setDataSummarySource([{
      key: 1,
      etapa: [...etapas.map(etapa => `${etapa.name}`), 'TOTAL'],
      forrajes: [...groupsum(summary.forrajes).map(forraje => `${forraje.cantidad}`), summary.forrajes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //forrajes: summary.forrajes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
      ingredientes: [...groupsum(summary.ingredientes).map(ingrediente => `${ingrediente.cantidad}`), summary.ingredientes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //ingredientes: summary.ingredientes.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
      productos: [...groupsum(summary.productos).map(producto => `${producto.cantidad}`), summary.productos.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //productos: summary.productos.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
      agua: [...groupsum(summary.agua).map(agua => `${agua.cantidad}`), summary.agua.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0)],
      //agua: summary.agua.map((item) => parseFloat(item.cantidad)).reduce((prev, curr) => prev + curr, 0),
    }])

    //console.log('forrajes', dataForrajeSource);
    //console.log('updateSummary', summary)
  }
  const getRegiones = async () => {
    const regiones = await props.requestRegiones(`?fields=*`);
    if (regiones.type === "FETCHING_REGIONS_DATA_SUCCESS") {
      AsyncStorage.setItem("regiones-full", JSON.stringify(regiones.data));
      return regiones.data;
    } else {
      if (!window.navigator.onLine) {
        let aux = await AsyncStorage.getItem("regiones-full");
        if (aux) {
          aux = JSON.parse(aux);
          return aux;
        }
      }
      return [];
    }
  };
  const getExcercises = async () => {
    //console.log("props.user", props.user);

    const exercices = await props.exercicesList(
      `?order=-date&owner=${props.user.user_id}`
      //`?order=-date`
    );
    if (exercices.type === "FETCHING_EXERCISE_DATA_SUCCESS") {
      let exerciceList = exercices.data.items.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });
      //console.log('exerciceList', exerciceList)
      setExcerciseList(exerciceList);
      return exerciceList;
    } else {
      if (!window.navigator.onLine) {
        let aux_excercise = await AsyncStorage.getItem("pendingTest");
        aux_excercise = JSON.parse(aux_excercise);
        aux_excercise = aux_excercise.slice(-1);
        return [aux_excercise];
      }
      return [];
    }
  };
  const getRecomendations = async (region_id) => {
    const recomendations = await props.requestRecomendations(
      `?region=${region_id}`
    );
    if (recomendations.type === "FETCHING_RECOMENDATIONS_DATA_SUCCESS") {
      //console.log("recomendationsList", recomendations.data.items);
      setRecomendationsList(recomendations.data.items);
      return recomendations.data.items;
    } else {
      return [];
    }
  };
  const getExcercise = async (currentId) => {
    const exercices = await props.exerciceRecord(currentId);
    if (exercices.type === "FETCHING_EXERCISE_DATA_SUCCESS") {
      console.log('exercices.data', exercices.data)
      return exercices.data;
    } else {
      if (!window.navigator.onLine) {
        let aux_excercise = await AsyncStorage.getItem("pendingTest");
        let aux_etapas = await AsyncStorage.getItem("etapas");
        let aux_forrajes = await AsyncStorage.getItem("forrajes");
        let aux_ingredientes = await AsyncStorage.getItem("ingredientes");
        let aux_productos = await AsyncStorage.getItem("productos");

        aux_excercise = JSON.parse(aux_excercise);
        aux_excercise = aux_excercise.slice(-1);

        aux_etapas = JSON.parse(aux_etapas);
        aux_forrajes = JSON.parse(aux_forrajes);
        aux_ingredientes = JSON.parse(aux_ingredientes);
        aux_productos = JSON.parse(aux_productos);

        aux_excercise = aux_excercise[0]

        aux_excercise.etapas = aux_excercise.etapas.map((etapa) => {
          etapa.id = etapa.etapa;
          etapa.etapa = aux_etapas.items.filter(
            (item) => item.id === etapa.id
          )[0].name;
          return etapa;
        });
        aux_excercise.forrajes = aux_excercise.forrajes.map((forraje) => {
          forraje.id = forraje.forraje;
          forraje.forraje = aux_forrajes.items.filter(
            (item) => item.forraje.id === forraje.id
          )[0].forraje.name;
          return forraje;
        });
        aux_excercise.ingredientes = aux_excercise.ingredientes.map((ingrediente) => {
          ingrediente.id = ingrediente.ingrediente;
          ingrediente.ingrediente = aux_ingredientes.items.filter(
            (item) => item.ingrediente.id === ingrediente.id
          )[0].ingrediente.name;
          return ingrediente;
        });
        aux_excercise.productos = aux_excercise.productos.map((producto) => {
          producto.id = producto.producto;
          producto.producto = aux_productos.items.filter(
            (item) => item.producto.id === producto.id
          )[0].producto.name;
          return producto;
        });

        //Agua
        let agua = aux_ingredientes.items.filter(
          item => item.ingrediente.name.toUpperCase() === 'AGUA'
        )[0]
        agua.id = agua.ingrediente.id;
        agua.ingrediente = agua.ingrediente.name;
        agua.precio = agua.price;
        aux_excercise.etapas.forEach(etapa => {
          agua.cantidad = parseInt(aux_excercise.agua.filter(agua => agua.etapa === etapa.id)[0].cantidad)
          agua.etapa = etapa.id;
          aux_excercise.ingredientes.push(agua)
        })

        aux_excercise.direccion = aux_excercise.rancho.direccion
        aux_excercise.email = aux_excercise.rancho.email
        aux_excercise.key = "offline";

        return aux_excercise;
      }
      return [];
    }
  };
  const getPromos = async (region_id) => {
    const promos = await props.requestPromos(`?region=${region_id}`);
    if (promos.type === "FETCHING_PROMOS_DATA_SUCCESS") {
      setPromos(promos.data);
      //console.log('promos', promos);
    }
  };
  const handleMenuClick = (e) => {
    //console.log('click', e);
    const newRoute = getRoute("/asuser/:id/results/:testId", id, e.key);
    navigate(`${newRoute}`);
    document.location.reload();
  };
  const newTest = async () => {
    await props.logAction({
      kind: "button",
      title: "Comenzar test",
      description: 'El usuario presiona el botón "Comenzar test"',
      date: new Date().valueOf(),
      user: props.user.username,
    });
    navigate(`/asuser/${props.user.user_id}/newtest`);
  };
  const saveTest = async () => {

    const mainTestData = {
      name: `${newName} ${new Date().toLocaleString()}`,
      etapas: [],
      forrajes: [],
      ingredientes: [],
      productos: [],
      agua: [],
      rancho: {
        nombre: excercice.nombre,
        direccion: excercice.direccion,
        telefono: excercice.telefono,
        email: excercice.email
      },
      region: excercice.region,
    };

    etapas.forEach((etapa) => {
      mainTestData.etapas.push({
        etapa: etapa.id,
        nvacas: excercice.etapas.filter(value => value.id === etapa.id)[0].nvacas,
      });
    });

    dataAguaSource.forEach((agua) => {
      mainTestData.agua = Object.keys(agua)
        .filter((key) => key !== "agua" && key !== "key")
        .map((item) => {
          const aux = item.split("_");
          return {
            etapa: parseInt(aux[1]),
            cantidad: agua[item],
          };
        });
    });
    dataForrajeSource.forEach((forraje) => {
      Object.keys(forraje)
        .filter((key) => key !== "forraje" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          const current = excercice.forrajes.filter(
            (item) => item.id === forraje.key
          )[0];
          mainTestData.forrajes.push({
            forraje: forraje.key,
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(forraje[item]),

            precio: parseFloat(current.precio),
            almd: parseFloat(current.almd),
            ms: parseFloat(current.ms),
            pc: parseFloat(current.pc),
          });
        });
    });
    dataIngredienteSource.forEach((ingrediente) => {
      Object.keys(ingrediente)
        .filter((key) => key !== "ingrediente" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          const current = excercice.ingredientes.filter(
            (item) => item.id === ingrediente.key
          )[0];
          mainTestData.ingredientes.push({
            ingrediente: ingrediente.key,
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(ingrediente[item]),

            precio: parseFloat(current.precio),
            almd: parseFloat(current.almd),
            ms: parseFloat(current.ms),
            pc: parseFloat(current.pc),
          });
        });
    });
    dataProductoSource.forEach((producto) => {
      Object.keys(producto)
        .filter((key) => key !== "producto" && key !== "key")
        .forEach((item) => {
          const aux = item.split("_");
          const current = excercice.productos.filter(
            (item) => item.id === producto.key
          )[0];
          mainTestData.productos.push({
            producto: producto.key,
            etapa: parseInt(aux[1]),
            cantidad: parseFloat(producto[item]),

            precio: parseFloat(current.precio),
            almd: parseFloat(current.almd),
            ms: parseFloat(current.ms),
            pc: parseFloat(current.pc),
            urea: parseFloat(current.urea),
          });
        });
    });

    mainTestData.owner = props.user.user_id;

    console.log('mainTestData', mainTestData)

    await props.logAction({
      kind: "button",
      title: "Siguiente",
      description: 'El usuario presiona el botón "Siguiente" en step 1 ',
      date: new Date().valueOf(),
      user: props.user.username,
    });

    //console.log('excercice', excercice)

    const startTest = await props.startTest(mainTestData);
    if (startTest.type === "FETCHING_EXERCISE_DATA_SUCCESS") {
      //console.log(startTest);
      await props.logAction({
        kind: "save",
        title: "Ejercicio creado",
        description: "El sistema asigno el Id " + startTest.data.ejercicio,
        date: new Date().valueOf(),
        user: props.user.username,
      });
      messageApi.success('Se ha guardado el ejercicio correctamente!');
      setTimeout(() => {
        navigate("/asuser/" + id + "/results/" + startTest.data.ejercicio, {
          state: null,
        });
        document.location.reload()
      }, 2000)
    } else {
      if (!window.navigator.onLine) {
        let pendingTest = await AsyncStorage.getItem("pendingTest");
        if (pendingTest) {
          pendingTest = JSON.parse(pendingTest);
        } else {
          pendingTest = [];
        }
        pendingTest.push(mainTestData);
        AsyncStorage.setItem("pendingTest", JSON.stringify(pendingTest));
        messageApi.success('Se ha guardado el ejercicio correctamente!');
        setTimeout(() => {
          navigate("/asuser/" + id + "/results/" + 0, {
            state: null,
          });
        }, 2000)
      }
      else {
        messageApi.error('Ocurrió un error, favor de reportarlo con el administrador!');
      }
    }

  }
  const printResults = async () => {

    //Eliminar en 0
    // eslint-disable-next-line 
    const aux_dataForrajeSource = dataForrajeSource.map(item => {
      const keys = Object.keys(item).filter(key => key !== 'key' && key !== 'forraje');
      let c = 0;
      keys.forEach(key => {
        c = c + parseFloat(item[key])
      })
      if (c > 0) { return item }
    }).filter(item => item !== undefined)
    // eslint-disable-next-line 
    const aux_dataIngredienteSource = dataIngredienteSource.map(item => {
      const keys = Object.keys(item).filter(key => key !== 'key' && key !== 'ingrediente');
      let c = 0;
      keys.forEach(key => {
        c = c + parseFloat(item[key])
      })
      if (c > 0) { return item }
    }).filter(item => item !== undefined)
    // eslint-disable-next-line 
    const aux_dataProductoSource = dataProductoSource.map(item => {
      const keys = Object.keys(item).filter(key => key !== 'key' && key !== 'producto');
      let c = 0;
      keys.forEach(key => {
        c = c + parseFloat(item[key])
      })
      if (c > 0) { return item }
    }).filter(item => item !== undefined)

    setDataForrajeSource(aux_dataForrajeSource)
    setDataIngredienteSource(aux_dataIngredienteSource)
    setDataProductoSource(aux_dataProductoSource)

    await reEvaluaData(aux_dataForrajeSource, aux_dataIngredienteSource, aux_dataProductoSource, null, true)

    document.querySelector('title').text = excercice.name;
    setTimeout(() => {
      window.print();
    }, 1000)

  };
  const reEvaluaData = async (d_forraje, d_ingrediente, d_producto, d_agua, update0 = false) => {
    const mainTestData = excercice;

    let dataForrajeSourceAux = d_forraje ? d_forraje : dataForrajeSource;
    let dataIngredienteSourceAux = d_ingrediente
      ? d_ingrediente
      : dataIngredienteSource;
    let dataProductoSourceAux = d_producto ? d_producto : dataProductoSource;
    let dataAguaSourceAux = d_agua ? d_agua : dataAguaSource;


    dataAguaSourceAux.forEach((agua) => {
      mainTestData.agua = Object.keys(agua)
        .filter((key) => key !== "agua" && key !== "key")
        .map((item) => {
          const aux = item.split("_");
          return {
            etapa: parseInt(aux[1]),
            cantidad: parseInt(agua[item]),
          };
        });
    });
    dataForrajeSourceAux.forEach((forraje) => {
      Object.keys(forraje)
        .filter((key) => key !== "forraje" && key !== "key" && key !== "precio")
        .forEach((item) => {
          const aux = item.split("_");
          mainTestData.forrajes
            .filter((f) => f.id === forraje.key)
            .filter((f) => f.etapa === parseInt(aux[1]))[0].cantidad = parseFloat(
              forraje[item]
            );
        });
    });
    dataIngredienteSourceAux.forEach((ingrediente) => {
      Object.keys(ingrediente)
        .filter((key) => key !== "ingrediente" && key !== "key" && key !== "precio")
        .forEach((item) => {
          const aux = item.split("_");
          mainTestData.ingredientes
            .filter((f) => f.id === ingrediente.key)
            .filter((f) => f.etapa === parseInt(aux[1]))[0].cantidad = parseFloat(
              ingrediente[item]
            );
        });
    });
    dataProductoSourceAux.forEach((producto) => {
      Object.keys(producto)
        .filter((key) => key !== "producto" && key !== "key" && key !== "precio")
        .forEach((item) => {
          const aux = item.split("_");
          mainTestData.productos
            .filter((f) => f.id === producto.key)
            .filter((f) => f.etapa === parseInt(aux[1]))[0].cantidad = parseFloat(
              producto[item]
            );
        });
    });

    //console.log('mainTestData.agua', mainTestData.agua);
    //console.log('dataForrajeSourceAux',dataForrajeSourceAux);
    //console.log("mainTestData", mainTestData);

    let etapas_aux = [];
    mainTestData.etapas.forEach((etapa) => {
      etapas_aux.push({
        id: etapa.id,
        name: etapa.etapa,
        nvacas: etapa.nvacas,
      });
    });

    etapas_aux.forEach((etapa) => {
      etapa.costo_productos = mainTestData.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => item.precio / 1000 * item.cantidad * etapa.nvacas)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.costo_forraje = mainTestData.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => item.precio / 1000 * item.cantidad * etapa.nvacas)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.costo_ingredientes = mainTestData.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => item.precio / 1000 * item.cantidad * etapa.nvacas)
        .reduce((prev, curr) => prev + curr, 0);

      etapa.costo_general = etapa.costo_productos + etapa.costo_forraje + etapa.costo_ingredientes;

      etapa.costo_individual = etapa.costo_general / etapa.nvacas;
      etapa.costo_productos_individual = etapa.costo_productos / etapa.nvacas;
      etapa.costo_forrajes_individual = etapa.costo_forraje / etapa.nvacas;
      etapa.costo_ingredientes_individual =
        etapa.costo_ingredientes / etapa.nvacas;

      //Evaluación x Etapa
      etapa.kg_bh_productos = mainTestData.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bh_forraje = mainTestData.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bh_ingredientes = mainTestData.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bh_general =
        etapa.kg_bh_productos + etapa.kg_bh_forraje + etapa.kg_bh_ingredientes;
      //etapa.kg_bh_individual = etapa.kg_bh_general / etapa.nvacas;

      etapa.kg_bs_productos = mainTestData.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bs_forraje = mainTestData.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bs_ingredientes = mainTestData.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bs_general =
        etapa.kg_bs_productos + etapa.kg_bs_forraje + etapa.kg_bs_ingredientes;
      //etapa.kg_bs_individual = etapa.kg_bs_general / etapa.nvacas;

      etapa.pc_tmr_productos = mainTestData.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.pc / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.pc_tmr_forrajes = mainTestData.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.pc / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.pc_tmr_ingredientes = mainTestData.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.pc / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.pc_tmr_general =
        etapa.pc_tmr_productos +
        etapa.pc_tmr_forrajes +
        etapa.pc_tmr_ingredientes;
      //etapa.pc_tmr_individual = etapa.pc_tmr_general / etapa.nvacas;

      etapa.almd_tmr_productos = mainTestData.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.almd / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.almd_tmr_forrajes = mainTestData.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.almd / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.almd_tmr_ingredientes = mainTestData.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.almd / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.almd_tmr_general =
        etapa.almd_tmr_productos +
        etapa.almd_tmr_forrajes +
        etapa.almd_tmr_ingredientes;
      //etapa.almd_tmr_individual = etapa.almd_tmr_general / etapa.nvacas;

      etapa.materia_seca = etapa.kg_bs_general;
      etapa.energia =
        (etapa.almd_tmr_general * 100) / (etapa.materia_seca
          ? etapa.materia_seca
          : 1);
      etapa.proteina =
        (etapa.pc_tmr_general * 100) / (etapa.materia_seca
          ? etapa.materia_seca
          : 1);
      etapa.forraje =
        (etapa.kg_bs_forraje * 100) / (etapa.materia_seca
          ? etapa.materia_seca
          : 1);


      const kh = mainTestData.ingredientes.filter(item => item.etapa === etapa.id).filter(item => item.ingrediente !== 'AGUA').map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0)

      const ag = mainTestData.agua
        .filter((a) => a.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0)


      etapa.humedad = 100 - (etapa.kg_bs_general * 100 / (kh + ag + etapa.kg_bh_productos + etapa.kg_bh_forraje))

      //console.log('humedad ' + etapa.name, etapa.kg_bs_general, (kh + ag), etapa.humedad)

      //etapa.humedad = 100 - (etapa.kg_bs_general * 100) / (etapa.kg_bh_general)
      /*etapa.humedad =
        100 - (etapa.materia_seca * 100) / (etapa.kg_bh_general
          ? etapa.kg_bh_general + mainTestData.agua
            .filter((a) => a.etapa === etapa.id)
            .map((item) => item.cantidad)
            .reduce((prev, curr) => prev + curr, 0)
          : 1)
        */
      etapa.key = etapa.id;
    });
    console.log('mainTestData', mainTestData);

    //Evaluamos el costo por etapa, si es 0 la quitamos
    let aux_etapas = null;
    if (update0) {
      // eslint-disable-next-line 
      aux_etapas = excercice.etapas.map(etapa => {
        const aux_etapa = etapas_aux.filter(item => item.id === etapa.id)[0]
        if (parseFloat(aux_etapa.costo_general) > 0) { return etapa }
      }).filter(item => item !== undefined)
      etapas_aux = etapas_aux.filter(item => aux_etapas.map(item => item.id).indexOf(item.id) !== -1)
    }

    dataForrajeSourceAux.forEach((item, idx) => {
      const keys = Object.keys(item).filter(key => key !== 'forraje' && key !== 'key')
      keys.forEach(key => {
        const etapa = key.split('_')[1]
        if (etapas_aux.map(item => item.id).indexOf(parseInt(etapa)) === -1) {
          delete (dataForrajeSourceAux[idx][key])
        }
      })
    })
    dataIngredienteSourceAux.forEach((item, idx) => {
      const keys = Object.keys(item).filter(key => key !== 'ingrediente' && key !== 'key')
      keys.forEach(key => {
        const etapa = key.split('_')[1]
        if (etapas_aux.map(item => item.id).indexOf(parseInt(etapa)) === -1) {
          delete (dataIngredienteSourceAux[idx][key])
        }
      })
    })
    dataProductoSourceAux.forEach((item, idx) => {
      const keys = Object.keys(item).filter(key => key !== 'producto' && key !== 'key')
      keys.forEach(key => {
        const etapa = key.split('_')[1]
        if (etapas_aux.map(item => item.id).indexOf(parseInt(etapa)) === -1) {
          delete (dataProductoSourceAux[idx][key])
        }
      })
    })
    dataAguaSourceAux.forEach((item, idx) => {
      const keys = Object.keys(item).filter(key => key !== 'agua' && key !== 'key')
      keys.forEach(key => {
        const etapa = key.split('_')[1]
        if (etapas_aux.map(item => item.id).indexOf(parseInt(etapa)) === -1) {
          delete (dataAguaSourceAux[idx][key])
        }
      })
    })

    //console.log('dataAguaSourceAux', dataAguaSourceAux);

    let ForrajeColumns = [
      {
        title: "Forraje",
        dataIndex: "forraje",
        editable: false,
        fixed: "left",
        width: 150,
      },
    ];
    let IngredienteColumns = [
      {
        title: "Ingrediente",
        dataIndex: "ingrediente",
        editable: false,
        fixed: "left",
        width: 150,
      },
    ];
    let ProductoColumns = [
      {
        title: "Producto",
        dataIndex: "producto",
        editable: false,
        fixed: "left",
        width: 150,
      },
    ];
    let AguaColumns = [
      {
        title: "Agua",
        dataIndex: "agua",
        editable: false,
        fixed: "left",
        width: 150,
      },
    ];

    etapas_aux.forEach((etapa) => {
      ForrajeColumns.push({
        title: etapa.name,
        dataIndex: `forraje_${etapa.id}`,
        editable: true,
      });
      IngredienteColumns.push({
        title: etapa.name,
        dataIndex: `ingrediente_${etapa.id}`,
        editable: true,
      });
      ProductoColumns.push({
        title: etapa.name,
        dataIndex: `producto_${etapa.id}`,
        editable: true,
      });
      AguaColumns.push({
        title: etapa.name,
        dataIndex: `agua_${etapa.id}`,
        editable: true,
      });
    });

    //console.log('excercice', excercice);
    console.log('excercice.etapas', etapas_aux);

    setColumnsForrajeSource(ForrajeColumns);
    setColumnsIngredienteSource(IngredienteColumns);
    setColumnsProductoSource(ProductoColumns);
    setColumnsAguaSource(AguaColumns);

    if (update0) {
      setExcercise({
        ...excercice,
        etapas: aux_etapas
      })
    }
    updateSummary();

    //console.log("dataForrajeSourceAux", dataForrajeSourceAux);
    //console.log("etapas_aux", etapas_aux);
    evaluaData(etapas_aux, regiones, mainTestData.region);
  };
  const setDataForrajeSourceHandler = async (data) => {
    setDataForrajeSource(data);
    reEvaluaData(data, null, null, null);
  };
  const setDataIngredienteSourceHandler = (data) => {
    setDataIngredienteSource(data);
    reEvaluaData(null, data, null, null);
  };
  const setDataProductoSourceHandler = (data) => {
    setDataProductoSource(data);
    reEvaluaData(null, null, data, null);
  };
  const setDataAguaSourceHandler = (data) => {
    setDataAguaSource(data);
    reEvaluaData(null, null, null, data);
  };
  const evaluaData = (etapas, regiones, region) => {
    //console.log('*************************');
    etapas.forEach((etapa) => {
      etapa._kg_materia_seca = "Muy deficiente";
      etapa._proteina = "Muy deficiente";
      etapa._energia = "Muy deficiente";
      etapa._forraje = "Muy deficiente";
      etapa._humedad = "Muy deficiente";

      const aux = decitionData(regiones, region);
      etapa.aux = aux[`etapa_${etapa.key}`];

      //console.log('aux',aux);

      if (etapa.materia_seca < etapa.aux.materia_seca[0]) {
        etapa._kg_materia_seca = "Muy deficiente";
      } else if (etapa.materia_seca < etapa.aux.materia_seca[1]) {
        etapa._kg_materia_seca = "Deficiente";
      } else if (etapa.materia_seca < etapa.aux.materia_seca[2]) {
        etapa._kg_materia_seca = "Óptimo";
      } else if (etapa.materia_seca < etapa.aux.materia_seca[3]) {
        etapa._kg_materia_seca = "Excedido";
      } else {
        etapa._kg_materia_seca = "Muy excedido";
      }

      if (etapa.energia < etapa.aux.energia[0]) {
        etapa._energia = "Muy deficiente";
      } else if (etapa.energia < etapa.aux.energia[1]) {
        etapa._energia = "Deficiente";
      } else if (etapa.energia < etapa.aux.energia[2]) {
        etapa._energia = "Óptimo";
      } else if (etapa.energia < etapa.aux.energia[3]) {
        etapa._energia = "Excedido";
      } else {
        etapa._energia = "Muy excedido";
      }

      if (etapa.proteina < etapa.aux.proteina[0]) {
        etapa._proteina = "Muy deficiente";
      } else if (etapa.proteina < etapa.aux.proteina[1]) {
        etapa._proteina = "Deficiente";
      } else if (etapa.proteina < etapa.aux.proteina[2]) {
        etapa._proteina = "Óptimo";
      } else if (etapa.proteina < etapa.aux.proteina[3]) {
        etapa._proteina = "Excedido";
      } else {
        etapa._proteina = "Muy excedido";
      }

      if (etapa.humedad < etapa.aux.humedad[0]) {
        etapa._humedad = "Muy deficiente";
      } else if (etapa.humedad < etapa.aux.humedad[1]) {
        etapa._humedad = "Deficiente";
      } else if (etapa.humedad < etapa.aux.humedad[2]) {
        etapa._humedad = "Óptimo";
      } else if (etapa.humedad < etapa.aux.humedad[3]) {
        etapa._humedad = "Excedido";
      } else {
        etapa._humedad = "Muy excedido";
      }

      if (etapa.forraje < etapa.aux.forraje[0]) {
        etapa._forraje = "Muy deficiente";
      } else if (etapa.forraje < etapa.aux.forraje[1]) {
        etapa._forraje = "Deficiente";
      } else if (etapa.forraje < etapa.aux.forraje[2]) {
        etapa._forraje = "Óptimo";
      } else if (etapa.forraje < etapa.aux.forraje[3]) {
        etapa._forraje = "Excedido";
      } else {
        etapa._forraje = "Muy excedido";
      }
    });

    setEtapas(etapas);
    //console.log("etapas", etapas);
    //console.log('*************************');
  };
  const loadData = (excercice) => {
    let etapas_aux = [];
    excercice.etapas.forEach((etapa) => {
      //console.log('etapa',etapa)
      etapas_aux.push({
        id: etapa.id,
        name: etapa.etapa,
        nvacas: etapa.nvacas,
      });
    });

    setNewName(excercice.nombre)

    etapas_aux.forEach((etapa) => {

      etapa.costo_productos = excercice.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => item.precio / 1000 * item.cantidad * etapa.nvacas)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.costo_forraje = excercice.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => item.precio / 1000 * item.cantidad * etapa.nvacas)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.costo_ingredientes = excercice.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => item.precio / 1000 * item.cantidad * etapa.nvacas)
        .reduce((prev, curr) => prev + curr, 0);

      etapa.costo_general = etapa.costo_productos + etapa.costo_forraje + etapa.costo_ingredientes;

      etapa.costo_individual = etapa.costo_general / etapa.nvacas;
      etapa.costo_productos_individual = etapa.costo_productos / etapa.nvacas;
      etapa.costo_forrajes_individual = etapa.costo_forraje / etapa.nvacas;
      etapa.costo_ingredientes_individual = etapa.costo_ingredientes / etapa.nvacas;

      //Evaluación x Etapa
      etapa.kg_bh_productos = excercice.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bh_forraje = excercice.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bh_ingredientes = excercice.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bh_general =
        etapa.kg_bh_productos + etapa.kg_bh_forraje + etapa.kg_bh_ingredientes;
      //etapa.kg_bh_individual = etapa.kg_bh_general / etapa.nvacas;

      etapa.kg_bs_productos = excercice.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bs_forraje = excercice.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bs_ingredientes = excercice.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.kg_bs_general =
        etapa.kg_bs_productos + etapa.kg_bs_forraje + etapa.kg_bs_ingredientes;
      //etapa.kg_bs_individual = etapa.kg_bs_general / etapa.nvacas;

      etapa.pc_tmr_productos = excercice.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.pc / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.pc_tmr_forrajes = excercice.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.pc / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.pc_tmr_ingredientes = excercice.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.pc / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);

      etapa.pc_tmr_general =
        etapa.pc_tmr_productos +
        etapa.pc_tmr_forrajes +
        etapa.pc_tmr_ingredientes;
      //etapa.pc_tmr_individual = etapa.pc_tmr_general / etapa.nvacas;

      etapa.almd_tmr_productos = excercice.productos
        .filter((producto) => producto.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.almd / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.almd_tmr_forrajes = excercice.forrajes
        .filter((forraje) => forraje.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.almd / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.almd_tmr_ingredientes = excercice.ingredientes
        .filter((ingrediente) => ingrediente.etapa === etapa.id)
        .map((item) => {
          return item.cantidad * (item.ms / 100) * (item.almd / 100);
        })
        .reduce((prev, curr) => prev + curr, 0);
      etapa.almd_tmr_general =
        etapa.almd_tmr_productos +
        etapa.almd_tmr_forrajes +
        etapa.almd_tmr_ingredientes;
      //etapa.almd_tmr_individual = etapa.almd_tmr_general / etapa.nvacas;

      etapa.materia_seca = etapa.kg_bs_general;

      etapa.proteina =
        (etapa.pc_tmr_general * 100) / (etapa.materia_seca
          ? etapa.materia_seca
          : 1);
      etapa.energia =
        (etapa.almd_tmr_general * 100) / (etapa.materia_seca
          ? etapa.materia_seca
          : 1);
      etapa.forraje =
        (etapa.kg_bs_forraje * 100) / (etapa.materia_seca
          ? etapa.materia_seca
          : 1);

      etapa.humedad = 100 - (etapa.kg_bs_general * 100) / (etapa.kg_bh_general)
      /*? etapa.kg_bh_general + excercice.ingredientes
        .filter((a) => a.etapa === etapa.id && a.ingrediente.toUpperCase() === 'AGUA')
        .map((item) => item.cantidad)
        .reduce((prev, curr) => prev + curr, 0)
      : 1)*/

      etapa.key = etapa.id;
    });

    let ForrajeSource = [];
    let IngredienteSource = [];
    let ProductoSource = [];
    let AguaSource = [];


    let ForrajeColumns = [
      {
        title: "Forraje",
        dataIndex: "forraje",
        editable: false,
        fixed: "left",
        width: 150,
        render: (value, record) => (<Typography.Text>{value}<br /><span className="short-price">{customFormat(record.precio)}</span></Typography.Text>)

      },
    ];
    let IngredienteColumns = [
      {
        title: "Ingrediente",
        dataIndex: "ingrediente",
        editable: false,
        fixed: "left",
        width: 150,
        render: (value, record) => (<Typography.Text>{value}<br /><span className="short-price">{customFormat(record.precio)}</span></Typography.Text>)
      },
    ];
    let ProductoColumns = [
      {
        title: "Producto",
        dataIndex: "producto",
        editable: false,
        fixed: "left",
        width: 150,
        render: (value, record) => (<Typography.Text>{value}<br /><span className="short-price">{customFormat(record.precio)}</span></Typography.Text>)
      },
    ];
    let AguaColumns = [
      {
        title: "Agua",
        dataIndex: "agua",
        editable: false,
        fixed: "left",
        width: 150,
      },
    ];

    const forrajes_aux = [...new Set(excercice.forrajes.map((f) => f.id))];
    forrajes_aux.forEach((f_id) => {
      let obj = {};
      obj["key"] = f_id;
      obj["forraje"] = excercice.forrajes.filter(
        (forraje) => forraje.id === f_id
      )[0].forraje;
      excercice.etapas.forEach((etapa) => {
        obj[`forraje_${etapa.id}`] = excercice.forrajes.filter(
          (forraje) => forraje.id === f_id && forraje.etapa === etapa.id
        )[0].cantidad;
        obj[`precio`] = excercice.forrajes.filter(
          (forraje) => forraje.id === f_id && forraje.etapa === etapa.id
        )[0].precio;
      });
      ForrajeSource.push(obj);
    });
    //console.log('ForrajeSource', ForrajeSource)
    const id_agua = excercice.ingredientes.filter(value => value.ingrediente.toUpperCase() === 'AGUA')[0].id;
    //console.log('id_agua', id_agua)
    const ingredientes_aux = [
      ...new Set(excercice.ingredientes.map((f) => f.id)),
    ];
    ingredientes_aux
      .filter((i) => i !== id_agua)
      .forEach((f_id) => {
        let obj = {};
        obj["key"] = f_id;
        obj["ingrediente"] = excercice.ingredientes.filter(
          (ingrediente) => ingrediente.id === f_id
        )[0].ingrediente;
        excercice.etapas.forEach((etapa) => {
          obj[`ingrediente_${etapa.id}`] = excercice.ingredientes.filter(
            (ingrediente) =>
              ingrediente.id === f_id && ingrediente.etapa === etapa.id
          )[0].cantidad;
          obj[`precio`] = excercice.ingredientes.filter(
            (ingrediente) => ingrediente.id === f_id && ingrediente.etapa === etapa.id
          )[0].precio;
        });
        IngredienteSource.push(obj);
      });
    const productos_aux = [...new Set(excercice.productos.map((f) => f.id))];
    productos_aux.forEach((f_id) => {
      let obj = {};
      obj["key"] = f_id;
      obj["producto"] = excercice.productos.filter(
        (producto) => producto.id === f_id
      )[0].producto;
      excercice.etapas.forEach((etapa) => {
        obj[`producto_${etapa.id}`] = excercice.productos.filter(
          (producto) => producto.id === f_id && producto.etapa === etapa.id
        )[0].cantidad;
        obj[`precio`] = excercice.productos.filter(
          (producto) => producto.id === f_id && producto.etapa === etapa.id
        )[0].precio;
      });
      ProductoSource.push(obj);
    });
    const agua_aux = [...new Set(excercice.ingredientes.map((f) => f.id))];
    console.log('agua_aux', agua_aux)
    agua_aux
      .filter((i) => i === id_agua)
      .forEach((f_id) => {
        let obj = {};
        obj["key"] = f_id;
        obj["agua"] = "Litros";
        excercice.etapas.forEach((etapa) => {
          obj[`agua_${etapa.id}`] = excercice.ingredientes.filter(
            (ingrediente) =>
              ingrediente.id === f_id && ingrediente.etapa === etapa.id
          )[0].cantidad;
        });
        AguaSource.push(obj);
      });

    excercice.etapas.forEach((etapa) => {
      ForrajeColumns.push({
        title: etapa.etapa,
        dataIndex: `forraje_${etapa.id}`,
        editable: true,
      });
      IngredienteColumns.push({
        title: etapa.etapa,
        dataIndex: `ingrediente_${etapa.id}`,
        editable: true,
      });
      ProductoColumns.push({
        title: etapa.etapa,
        dataIndex: `producto_${etapa.id}`,
        editable: true,
      });
      AguaColumns.push({
        title: etapa.etapa,
        dataIndex: `agua_${etapa.id}`,
        editable: true,
      });
    });


    //console.log('excercice', excercice);
    console.log('excercice.etapas', etapas_aux);

    setColumnsForrajeSource(ForrajeColumns);
    setDataForrajeSource(ForrajeSource);
    setColumnsIngredienteSource(IngredienteColumns);
    setDataIngredienteSource(IngredienteSource);
    setColumnsProductoSource(ProductoColumns);
    setDataProductoSource(ProductoSource);
    setColumnsAguaSource(AguaColumns);
    setDataAguaSource(AguaSource);


    getRegiones().then((r) => {
      setRegiones(r.items);
      evaluaData(etapas_aux, r.items, excercice.region);
      setComponentReady(true);
    });

    getRecomendations(excercice.region.id);
    getPromos(excercice.region.id);
  };

  useEffect(() => {
    if (typeof props.user.user_id === "undefined") {
      return;
    }
    getExcercises().then((exerciceList) => {
      //console.log('exerciceList', exerciceList);
      if (exerciceList.length === 0) {
        navigate("/");
        return;
      }
      getExcercise(testId === "0" ? exerciceList[0].key : testId).then(
        (excercice) => {
          //console.log("excercice", excercice);
          setExcercise(excercice);
          loadData(excercice);
        }
      );
    });
    // eslint-disable-next-line
  }, [props.user.user_id]);

  if (!componentReady) {
    return <Layout style={{ minHeight: 600 }}></Layout>;
  }

  const stages = [
    {
      name: 'Muy deficiente',
      color: '#0000ff'
    },
    {
      name: 'Deficiente',
      color: 'yellow'
    },
    {
      name: 'Óptimo',
      color: 'green'
    },
    {
      name: 'Excedido',
      color: 'orange'
    },
    {
      name: 'Muy excedido',
      color: 'red'
    }
  ];

  return (
    <Layout className="body-container">
      {contextHolder}

      <Row className="just-print">
        <img alt="" width={100} src="/assets/images/ADM-Logo-Primary-300x225.webp"></img>
      </Row>

      <Row className="no-print" gutter={{ xs: 8, sm: 14 }}>
        <Col span={23}>
          <Paragraph style={{ margin: 0, textAlign: "end" }}>
            Ingresaste como {props.user.first_name}
          </Paragraph>
          <Paragraph style={{ textAlign: "end" }} strong type="success">
            {props.user.email} {/*Tangamandapio, Mich.*/}
          </Paragraph>
        </Col>
        <Col span={1} style={{ marginBlockStart: 5 }}>
          <UserOutlined className="icon-user-logged" />
        </Col>
      </Row>
      {excercice && (
        <Title style={{ margin: 0, fontSize: 24, textAlign: 'center' }} type="success">
          {excercice.name}
        </Title>
      )}
      <Text>
        <br />
      </Text>

      <FloatButton
        icon={showEditable ? <EyeOutlined
          style={{
            fontSize: 25,
            position: 'absolute',
            left: '31%',
            top: 0,
            bottom: 0,
          }}
        /> : <EyeInvisibleOutlined
          style={{
            fontSize: 25,
            position: 'absolute',
            left: '31%',
            top: 0,
            bottom: 0,
          }}
        />}
        onClick={() => {
          setShowEditable(!showEditable)
          setTimeout(() => {
            if (!showEditable) {
              document.querySelector('#edicion-por-etapa').scrollIntoView()
            }
            else {
              document.querySelector('#evaluacion-por-etapa').scrollIntoView()
            }

          }, 500)

        }}
        type="primary"
        target={'#evaluacion-por-etapa'}
        style={{
          width: 60,
          height: 60,
        }}
      />

      {excercice && (
        <Col span={24}>
          <Row style={{ marginTop: 10 }} className="rancho-data">
            <Input
              addonBefore="Nombre del Rancho"
              readOnly={true}
              type="text"
              value={
                window.navigator.onLine
                  ? excercice.nombre
                  : excercice.rancho.nombre
              }
            />
            <Input
              addonBefore="Dirección del Rancho"
              readOnly={true}
              type="text"
              value={
                window.navigator.onLine
                  ? excercice.direccion
                  : excercice.rancho.direccion
              }
            />
            <Input
              addonBefore="Teléfono de contacto"
              readOnly={true}
              type="tel"
              value={
                window.navigator.onLine
                  ? excercice.telefono
                  : excercice.rancho.telefono
              }
            />
            <Input
              addonBefore="Email de contacto"
              readOnly={true}
              type="email"
              value={
                window.navigator.onLine
                  ? excercice.email
                  : excercice.rancho.email
              }
            />
          </Row>
          <Col span={24} style={{ marginTop: 20 }}>
            <Row>
              <Col span={8}>
                <Input
                  addonBefore="Region"
                  disabled={true}
                  value={excercice.region.name}
                />
              </Col>
            </Row>
          </Col>
        </Col>
      )}

      <Space wrap className="space-results" style={{ marginBlockStart: 30 }}>
        {excercicesList.length > 0 && (
          <Dropdown.Button
            menu={{
              items: excercicesList,
              onClick: handleMenuClick,
              selectable: true,
            }}
            icon={<DownOutlined />}
          >
            Seleccione un prueba
          </Dropdown.Button>
        )}
        <Button onClick={newTest} type="primary">
          Nuevo test
        </Button>

        <Popconfirm
          title="Title"
          description={() => {
            return (<Row>
              <Col span={24}>
                <Input value={newName} onChange={(e) => {
                  setNewName(e.target.value)
                }}></Input>
              </Col>
            </Row>)
          }}
          open={openSaveAsNew}
          onConfirm={saveTest}
          onCancel={handleCancelSaveTest}
        >
          <Button onClick={showPopconfirmSaveAsNew} type="default" >
            Guardar en nuevo
          </Button>
        </Popconfirm>

        <Button
          onClick={() => {
            setShowEditable(!showEditable);
          }}
          type="primary"
          ghost
        >
          {showEditable ? "Ocultar edición" : "Mostrra edición"}
        </Button>
        <Button onClick={printResults} icon={<PrinterOutlined />}>
          Imprimir
        </Button>
      </Space>

      <Anchor
        direction="horizontal"
        items={itemsMenu}
        affix={false}
        getCurrentAnchor={getCurrentAnchor}
        style={{ marginBlockStart: 40 }}
      />

      <Card
        id="costos-generales"
        style={{ marginTop: 20 }}
        title="Costos Generales"
        bordered={false}
        bodyStyle={{ padding: 50 }}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <div className="cost-per-day">
              <Text type="success" strong style={{ fontSize: 20 }}>
                Costo por día{" "}
                {currencyFormat(
                  etapas
                    .map((item) => item.costo_general)
                    .reduce((prev, curr) => prev + curr, 0)
                    .toFixed(2)
                )}{" "}
              </Text>
            </div>
            <Paragraph>
              <div
                dangerouslySetInnerHTML={{
                  __html: step3 ? step3.costo_x_grupo_title : "",
                }}
              />
            </Paragraph>
            <TableCostosGenerales etapas={etapas} individual={false} />
            <br />
            <Paragraph>
              <div
                dangerouslySetInnerHTML={{
                  __html: step3 ? step3.costo_x_vaca_title : "",
                }}
              />
            </Paragraph>
            <TableCostosGenerales etapas={etapas} individual={true} />
          </Col>
        </Row>
      </Card>

      <Card bodyStyle={{ padding: 50 }} style={{ marginTop: 20 }}>
        <Row>
          <Paragraph>
            <div
              dangerouslySetInnerHTML={{
                __html: step3 ? step3.dieta_title : "",
              }}
            />
          </Paragraph>
        </Row>
        <Row >
          <Col span={24}>
            <EditableTable
              columnsOSource={columnsForrajeSource}
              dataOSource={dataForrajeSource}
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <EditableTable
              columnsOSource={columnsIngredienteSource}
              dataOSource={dataIngredienteSource}
              stateChanger={setDataIngredienteSourceHandler}
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <EditableTable
              columnsOSource={columnsProductoSource}
              dataOSource={dataProductoSource}
              stateChanger={setDataProductoSourceHandler}
              readOnly={true}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <EditableTable
              columnsOSource={columnsAguaSource}
              dataOSource={dataAguaSource}
              stateChanger={setDataAguaSourceHandler}
              readOnly={true}
            />
          </Col>
        </Row>
      </Card>

      <Card bodyStyle={{ padding: 50 }} style={{ marginTop: 20 }}>
        <Row>
          <Col span={24}>
            <Paragraph>
              <div
                dangerouslySetInnerHTML={{
                  __html: step3 ? step3.resumen_dieta_title : "",
                }}
              />
            </Paragraph>
          </Col>
          <Col span={24}>
            {shortSummaryColumns.length > 0 && shortSummarySource.length > 0 && (
              <EditableTable
                readOnly={true}
                columnsOSource={shortSummaryColumns}
                dataOSource={shortSummarySource}
                stateChanger={setShortSummarySource}
              />
            )}
          </Col>
          <Col span={24}>
            <br />
            <Table className="readOnlyTable" dataSource={dataSummarySource} columns={columnsSummarySource} pagination={false} />
          </Col>
        </Row>
      </Card>

      <Modal title={
        <Row>
          <Col style={{ marginBottom: 20 }}>
            <Space wrap>
              Edición de datos:
              {stages.map(stage => (
                <Badge key={stage.color} color={stage.color} text={stage.name} />
              ))}
            </Space>
          </Col>
        </Row>
      }
        open={showEditable}
        centered
        width={'100%'}
        onOk={() => {
          setShowEditable(false)
          setTimeout(() => {
            if (!showEditable) {
              document.querySelector('#edicion-por-etapa').scrollIntoView()
            }
            else {
              document.querySelector('#evaluacion-por-etapa').scrollIntoView()
            }

          }, 500)
        }}
        onCancel={() => {
          setShowEditable(false)
          setTimeout(() => {
            if (!showEditable) {
              document.querySelector('#edicion-por-etapa').scrollIntoView()
            }
            else {
              document.querySelector('#evaluacion-por-etapa').scrollIntoView()
            }

          }, 500)
        }}
      >
        <Card id="edicion-por-etapa">
          <Row>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
              <table style={{ border: '1px solid #efefef', textAlign: 'center' }}>
                <thead>
                  <tr>
                    {etapas.map(etapa => {
                      return (<td key={etapa.id} style={{ width: window.innerWidth / (etapas.length + 1) }}>{etapa.name}</td>)
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {etapas.map(etapa => {
                      return (<td key={etapa.id} className="tiny-block">
                        <button className={etapa._kg_materia_seca.toUpperCase().split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')} title="Materia Seca">MS</button>
                        <button className={etapa._energia.toUpperCase().split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')} title="Energía">EN</button>
                        <button className={etapa._forraje.toUpperCase().split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')} title="Forraje">FO</button>
                        <button className={etapa._proteina.toUpperCase().split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')} title="Proteina">PR</button>
                        <button className={etapa._humedad.toUpperCase().split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')} title="Humedad">HU</button>
                      </td>)
                    })}
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <EditableTable
                columnsOSource={columnsForrajeSource}
                dataOSource={dataForrajeSource}
                stateChanger={setDataForrajeSourceHandler}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <EditableTable
                columnsOSource={columnsIngredienteSource}
                dataOSource={dataIngredienteSource}
                stateChanger={setDataIngredienteSourceHandler}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <EditableTable
                columnsOSource={columnsProductoSource}
                dataOSource={dataProductoSource}
                stateChanger={setDataProductoSourceHandler}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <EditableTable
                columnsOSource={columnsAguaSource}
                dataOSource={dataAguaSource}
                stateChanger={setDataAguaSourceHandler}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {shortSummaryColumns.length > 0 && shortSummarySource.length > 0 && (
                <EditableTable
                  readOnly={true}
                  columnsOSource={shortSummaryColumns}
                  dataOSource={shortSummarySource}
                  stateChanger={setShortSummarySource}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Modal>

      <Card
        id="evaluacion-por-etapa"
        style={{ marginTop: 20 }}
        title="Evaluación por etapa"
        bordered={false}
        bodyStyle={{
          paddingInline: 50,
          paddingBlockEnd: 50,
          paddingBlockStart: 20,
        }}
      >
        <Row>
          <Col span={24}>
            <EvaluacionPorEtapa etapas={etapas} />
          </Col>
        </Row>
      </Card>

      {recomendationsList && recomendationsList.length > 0 && (
        <Card
          id="recomendaciones-generales"
          style={{ marginTop: 20 }}
          title="Recomendaciones generales"
          bordered={false}
          bodyStyle={{
            paddingInline: 50,
            paddingBlockEnd: 50,
            paddingBlockStart: 20,
          }}
        >
          <Row>
            <Col span={24}>
              <GeneralRecommendations recomendationsList={recomendationsList} />
            </Col>
          </Row>
        </Card>
      )}

      {promos && promos.items && promos.items.length > 0 && (
        <Card
          id="promociones"
          style={{ marginTop: 20 }}
          title="PROMOCIONES"
          bordered={false}
          bodyStyle={{
            paddingInline: 50,
            paddingBlockEnd: 50,
            paddingBlockStart: 20,
          }}
        >
          <Row>
            <Col span={24}>
              <Promotions promos={promos} />
            </Col>
          </Row>
        </Card>
      )}

      <Card style={{ marginTop: 20 }} bodyStyle={{ padding: 50 }}>
        <Paragraph>
          Esta información nutricional es una guía, los resultados están sujetos a genética, ambiente, sanidad y manejo en granja.
          Ajustar la dieta con base en días en leche, condición corporal, calidad de forrajes, objetivos deseados, salud del animal, entre otros.
          Mantener en todo momento agua limpia y fresca disponible para el ganado y ofrecer siempre el forraje de la mejor calidad posible.
        </Paragraph>
      </Card>

    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logAction: (data) => dispatch(actions.logAction(data)),
    startTest: (data) => dispatch(eactions.startTest(data)),
    exercicesList: (data) => dispatch(eactions.exercicesList(data)),
    exerciceRecord: (data) => dispatch(eactions.exerciceRecord(data)),
    requestPromos: (data) => dispatch(pactions.requestPromos(data)),
    requestRecomendations: (data) =>
      dispatch(ractions.requestRecomendations(data)),
    requestRegiones: (data) => dispatch(gactions.requestRegiones(data)),
    requestStep3Page: (data) => dispatch(pages_actions.requestStep3Page(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UResults);
