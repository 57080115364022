import { connect } from "react-redux";
import { Button, Card, Col, Divider, Layout, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import * as pages_actions from "../actions/pages";
import * as actions from "../actions/user";
import * as eactions from "../actions/exercises";

import { AsyncStorage } from "AsyncStorage";
//import Paragraph from "antd/es/skeleton/Paragraph";

const { Title } = Typography;

export const Home = (props) => {
  const navigate = useNavigate();
  const [home, setHome] = useState(null);

  const onClickNewTest = async () => {
    await props.logAction({
      kind: "button",
      title: "Comenzar test",
      description: 'El usuario presiona el botón "Comenzar test"',
      date: new Date().valueOf(),
      user: props.user.username,
    });
    navigate(`asuser/${props.user.user_id}/newtest`);
  };

  const onClickResults = () => {
    navigate(`asuser/${props.user.user_id}/results/0`);
  };

  const getAsyncProfile = async () => {
    const data = await AsyncStorage.getItem("profile");
    if (typeof data === "undefined") {
      return {};
    }
    return JSON.parse(data);
  };

  const preloadImages = async (imageUrls) => {
    const promises = [];
    const images = [];
    const number_of_urls = imageUrls.length;

    for (let i = 0; i < number_of_urls; i++) {
      const img = new Image();
      images.push(img);
      promises.push(
        new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        })
      );
      img.crossOrigin = "anonymous";
      img.src = imageUrls[i];
    }
    await Promise.all(promises);
    return images;
  };

  useEffect(() => {
    if (!props.user || !props.user.authenticated) {
      getAsyncProfile().then((r) => {
        console.log("asyncProfile", r);
        if (!r || !r.authenticated) {
          navigate("/login");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getHome = async () => {
      console.log("TEST Home-Offline X1");

      let home = await props.requestHomePage();
      if (home.type === "FETCHING_PAGES_DATA_SUCCESS") {
        setHome(home.data.items[0]);

        preloadImages([
          home.data.items[0].acerca_image.full_url,
          home.data.items[0].comienza_image.full_url,
          ...home.data.items[0].marcas.map((e) => e.image.full_url),
        ]).then((images) => {
          //console.log("images", images);

          var c1 = document.createElement("canvas");
          var c2 = document.createElement("canvas");
          c1.height = images[0].naturalHeight;
          c1.width = images[0].naturalWidth;
          c2.height = images[1].naturalHeight;
          c2.width = images[1].naturalWidth;
          var ctx1 = c1.getContext("2d");
          var ctx2 = c2.getContext("2d");
          ctx1.drawImage(images[0], 0, 0, c1.width, c1.height);
          ctx2.drawImage(images[1], 0, 0, c2.width, c2.height);

          home = home.data.items[0];
          home.acerca_image.b64 = c1.toDataURL();
          home.comienza_image.b64 = c2.toDataURL();

          images.shift();
          images.shift();

          //console.log("images", images);
          images.forEach((image, idx) => {
            c1 = document.createElement("canvas");
            c1.height = image.naturalHeight;
            c1.width = image.naturalWidth;
            ctx1 = c1.getContext("2d");
            ctx1.drawImage(image, 0, 0, c1.width, c1.height);
            home.marcas[idx].image.b64 = c1.toDataURL();
          });

          //console.log('c1.toDataURL', home.marcas[0].image.b64)
          AsyncStorage.setItem("home", JSON.stringify(home));
        });
      } else {
        if (!window.navigator.onLine) {
          let home = await AsyncStorage.getItem("home");
          if (home) {
            home = JSON.parse(home);
            setHome(home);
          }
        }
      }
    };
    getHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Sync lastTest
  useEffect(() => {
    if (window.navigator.onLine) {
      AsyncStorage.getItem("pendingTest").then(async (e) => {
        if (e) {
          const pendingTest = JSON.parse(e);
          pendingTest.forEach(async (pending) => {
            await props.startTest(pending);
          });
          AsyncStorage.setItem("pendingTest", "[]");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Card style={{ margin: "0 16px" }}>
        <div style={{ padding: 35, background: "#fff" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} lg={6}>
              <img
                src={
                  window.navigator.onLine
                    ? home && home.acerca_image
                      ? home.acerca_image.full_url
                      : ""
                    : home && home.acerca_image
                      ? home.acerca_image.b64
                      : ""
                }
                alt=""
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  marginInline: "auto",
                  marginBlock: 20,
                }}
              />
            </Col>
            <Col xs={24} lg={18}>
              <Title style={{ marginTop: 0, color: "#01027C" }}>
                LECHER<span style={{ color: "#01AD51" }}>app</span>
              </Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: home ? home.acerca_paragraph : "",
                }}
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} lg={6}>
              <img
                src={
                  window.navigator.onLine
                    ? home && home.comienza_image
                      ? home.comienza_image.full_url
                      : ""
                    : home && home.acerca_image
                      ? home.comienza_image.b64
                      : ""
                }
                alt=""
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  marginInline: "auto",
                  marginBlock: 20,
                }}
              />
            </Col>
            <Col xs={24} lg={18}>
              <Title style={{ marginTop: 0 }}>Comienza aquí</Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: home ? home.comienza_paragraph : "",
                }}
              />
              <Row style={{ marginTop: 30 }}>
                <Col xs={24} md={10} lg={10}>
                  <Button type="primary" block onClick={onClickNewTest}>
                    Comenzar test
                  </Button>
                </Col>
                <Col xs={24} md={4} lg={4}></Col>
                <Col xs={24} md={10} lg={10}>
                  <Button type="primary" ghost onClick={onClickResults}>
                    Ir a los resultados
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Title>Marcas especializadas</Title>
            <div
              dangerouslySetInnerHTML={{
                __html: home ? home.marcas_paragraph : "",
              }}
            />
            <Row gutter={[24, 12]} className="cardBrandContainer">
              {home &&
                home.marcas.map((marca) => {
                  return (
                    <a href={marca.url} key={marca.id}>
                      <Card
                        className="cardBrand"
                        span={24}
                        style={{
                          padding: 0,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            minWidth: 200,
                            height: 200,
                            backgroundImage: window.navigator.onLine
                              ? `url(${marca.image.full_url})`
                              : `url(${marca.image.full_url})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "auto",
                          }}
                        ></div>
                        <div
                          style={{
                            background: "#01027C",
                            color: "#fff",
                            padding: 5,
                            minHeight: 60,
                            borderRadius: "0 0 5px 5px",
                            maxWidth: 200,
                          }}
                        >
                          {marca.caption}
                        </div>
                      </Card>
                    </a>
                  );
                })}
            </Row>
          </Row>
        </div>
      </Card>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestHomePage: (data) => dispatch(pages_actions.requestHomePage(data)),
    logAction: (data) => dispatch(actions.logAction(data)),
    startTest: (data) => dispatch(eactions.startTest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
