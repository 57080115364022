import {
  FETCHING_EXERCISE_DATA,
  FETCHING_EXERCISE_DATA_SUCCESS,
  FETCHING_EXERCISE_DATA_FAILURE,
} from "../constants/actionTypes.js";
//import {API_URL,} from "../constants/endpoints";
import api from "../api/exercises.js";

export const exercicesList = (data) => {
  return async (dispatch) => {
    dispatch(getData(data));
    try {
      const [response, json] = await api("exercicesList", data);
      json.statusCode = response.status;
      return dispatch(getDataSuccess(json));
    } catch (err) {
      console.log(err);
      return getDataFailure();
    }
  };
};
export const exerciceRecord = (data) => {
  return async (dispatch) => {
    dispatch(getData(data));
    try {
      const [response, json] = await api("exerciceRecord", data);
      json.statusCode = response.status;
      return dispatch(getDataSuccess(json));
    } catch (err) {
      console.log(err);
      return getDataFailure();
    }
  };
};
export const startTest = (data) => {
  return async (dispatch) => {
    dispatch(getData(data));
    try {
      const [response, json] = await api("startTest", data);
      json.statusCode = response.status;
      return dispatch(getDataSuccess(json));
    } catch (error) {
      console.log(error);
      return getDataFailure();
    }
  };
};

//API Requests
const getData = (data) => {
  return {
    type: FETCHING_EXERCISE_DATA,
    payload: data,
    /*meta:{
            offline: {
                effect:   { url: `${API_URL}pages/?type=home.HomePage&fields=*`, method: 'GET' },
                commit:   { type: 'FETCHING_EXCERCISE_DATA_SUCCESS', meta: { data } },
                rollback: { type: 'FETCHING_EXERCISE_DATA_FAILURE', meta: { data } }
            }
        }*/
  };
};
const getDataSuccess = (data) => {
  return {
    type: FETCHING_EXERCISE_DATA_SUCCESS,
    data,
  };
};
const getDataFailure = () => {
  return {
    type: FETCHING_EXERCISE_DATA_FAILURE,
  };
};
