import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Layout,
  Button,
  Typography,
  Card,
  Space,
  Checkbox,
  Input,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { AsyncStorage } from "AsyncStorage";

import * as pages_actions from "../actions/pages";
import * as actions from "../actions/user";
import * as eactions from "../actions/etapas";
import * as pactions from "../actions/productos";
import * as iactions from "../actions/ingredientes";
import * as factions from "../actions/forrajes";
import * as ractions from "../actions/regiones";

const { Title, Paragraph } = Typography;

const UNewTest = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState({});
  const [etapas, setEtapas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [ingredientes, setIngredientes] = useState([]);
  const [forrajes, setForrajes] = useState([]);
  const [regiones, setRegiones] = useState([]);

  const [region, setRegion] = useState(null);
  const [rancho] = useState({});

  const [selectedEtapas, setSelectedEtapas] = useState([]);
  const [selectedProductos, setSelectedProductos] = useState([]);
  const [selectedIngredientes, setSelectedIngredientes] = useState([]);
  const [selectedForrajes, setSelectedForrajes] = useState([]);

  const onCancel = () => {
    navigate("/");
  };
  const onNext = async () => {
    console.log({
      selectedEtapas,
      selectedForrajes,
      selectedIngredientes,
      selectedProductos,

      rancho,
      etapas,
      forrajes,
      ingredientes,
      productos,
      region,
    });

    await props.logAction({
      kind: "button",
      title: "Siguiente",
      description: 'El usuario presiona el botón "Siguiente" en step 0 ',
      date: new Date().valueOf(),
      user: props.user.username,
    });

    navigate("/asuser/" + id + "/newtest/step1", {
      state: {
        selectedEtapas,
        selectedForrajes,
        selectedIngredientes,
        selectedProductos,

        region,
        rancho,
        etapas,
        forrajes,
        ingredientes,
        productos,
      },
    });
  };
  const getEtapas = async () => {
    const etapas = await props.requestEtapas();
    if (etapas.type === "FETCHING_ETAPAS_DATA_SUCCESS") {
      AsyncStorage.setItem("etapas", JSON.stringify(etapas.data));
      return etapas.data;
    } else {
      if (!window.navigator.onLine) {
        let aux = await AsyncStorage.getItem("etapas");
        if (aux) {
          aux = JSON.parse(aux);
          return aux;
        }
      }
      return [];
    }
  };
  const getProductos = async (region) => {
    const productos = await props.requestProductos(
      `?region=${region}&fields=*`
    );
    if (productos.type === "FETCHING_PRODUCTOS_DATA_SUCCESS") {
      AsyncStorage.setItem("productos", JSON.stringify(productos.data));
      return productos.data;
    } else {
      if (!window.navigator.onLine) {
        let aux = await AsyncStorage.getItem("productos");
        if (aux) {
          aux = JSON.parse(aux);
          return aux;
        }
      }
      return [];
    }
  };
  const getIngredientes = async (region) => {
    const ingredientes = await props.requestIngredientes(
      `?region=${region}&fields=*`
    );
    if (ingredientes.type === "FETCHING_INGREDIENTES_DATA_SUCCESS") {
      AsyncStorage.setItem("ingredientes", JSON.stringify(ingredientes.data));
      return ingredientes.data;
    } else {
      if (!window.navigator.onLine) {
        let aux = await AsyncStorage.getItem("ingredientes");
        if (aux) {
          aux = JSON.parse(aux);
          return aux;
        }
      }
      return [];
    }
  };
  const getForrajes = async (region) => {
    const forrajes = await props.requestForrajes(`?region=${region}&fields=*`);
    if (forrajes.type === "FETCHING_FORRAJES_DATA_SUCCESS") {
      AsyncStorage.setItem("forrajes", JSON.stringify(forrajes.data));
      return forrajes.data;
    } else {
      if (!window.navigator.onLine) {
        let aux = await AsyncStorage.getItem("forrajes");
        if (aux) {
          aux = JSON.parse(aux);
          return aux;
        }
      }
      return [];
    }
  };
  const getRegiones = async () => {
    const regiones = await props.requestRegiones("");
    if (regiones.type === "FETCHING_REGIONS_DATA_SUCCESS") {
      AsyncStorage.setItem("regiones", JSON.stringify(regiones.data));
      return regiones.data;
    } else {
      if (!window.navigator.onLine) {
        let aux = await AsyncStorage.getItem("regiones");
        if (aux) {
          aux = JSON.parse(aux);
          return aux;
        }
      }
      return [];
    }
  };

  const onRegionsChange = (e) => {
    console.log("onRegionsChange", e);
    setRegion(regiones.filter(value => value.id === e)[0]);

    getForrajes(e).then((r) => {
      console.log("Forrajes", r);
      setForrajes(r.items);
    });
    getIngredientes(e).then((r) => {
      console.log("Ingredientes", r);
      setIngredientes(r.items);
    });
    getProductos(e).then((r) => {
      console.log("Productos", r);
      setProductos(r.items);
    });
  };
  const onEtapaChange = (e) => {
    setSelectedEtapas(e);
  };
  const onProductoChange = (e) => {
    setSelectedProductos(e);
  };
  const onIngredienteChange = (e) => {
    setSelectedIngredientes(e);
  };
  const onForrajeChange = (e) => {
    setSelectedForrajes(e);
  };

  const saveUnits = (e, data) => {
    data.units = e.target.value;
  };
  const savePrice = (e, data) => {
    data.price = e.target.value;
  };
  const savePC = (e, data) => {
    data.pc = e.target.value;
  };
  const saveMS = (e, data) => {
    data.ms = e.target.value;
  };
  const saveALMD = (e, data) => {
    data.almd = e.target.value;
  };

  const getAsyncProfile = async () => {
    const data = await AsyncStorage.getItem("profile");
    if (typeof data === "undefined") {
      return {};
    }
    return JSON.parse(data);
  };

  useEffect(() => {
    if (!props.user || !props.user.authenticated) {
      getAsyncProfile().then((r) => {
        console.log("asyncProfile", r);
        if (!r || !r.authenticated) {
          navigate("/login");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEtapas().then((r) => {
      console.log("etapas", r);
      r.items.forEach((etapa) => {
        etapa.units = 0;
      });
      setEtapas(r.items);
    });
    getRegiones().then((r) => {
      setRegiones(r.items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getPage = async () => {
      let page = await props.requestStep0Page();
      if (page.type === "FETCHING_PAGES_DATA_SUCCESS") {
        AsyncStorage.setItem("step0", JSON.stringify(page.data.items[0]));
        setPage(page.data.items[0]);
      } else {
        if (!window.navigator.onLine) {
          let page = await AsyncStorage.getItem("step0");
          if (page) {
            page = JSON.parse(page);
            setPage(page);
          }
        }
      }
    };
    getPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Row gutter={{ xs: 8, sm: 14 }}>
        <Col span={23}>
          <Paragraph style={{ margin: 0, textAlign: "end" }}>
            Ingresaste como {props.user.first_name}
          </Paragraph>
          <Paragraph style={{ textAlign: "end" }} strong type="success">
            {props.user.email} {/*Tangamandapio, Mich.*/}
          </Paragraph>
        </Col>
        <Col span={1} style={{ marginBlockStart: 5 }}>
          <UserOutlined className="icon-user-logged" />
        </Col>
      </Row>
      <Title style={{ margin: 0 }} type="success">
        {page.intro_title}
      </Title>
      <div
        className="info_paragraph"
        dangerouslySetInnerHTML={{
          __html: page ? page.intro_paragraph : "",
        }}
      ></div>

      <Card style={{ marginTop: 20, padding: 40 }}>
        <Col xs={0} md={24}>
          <strong>Información del Rancho</strong>
          <Paragraph style={{ margin: 0, textAlign: "start" }}>
            A continuación llena con la información de el Raancho para el que
            vamos a realizar este test.
          </Paragraph>
          <Row style={{ marginTop: 10 }} className="rancho-data">
            <Input
              addonBefore="Nombre del Rancho"
              type="text"
              onChange={(e) => {
                rancho.nombre = e.target.value;

                if (props.user.region && region === null) {
                  console.log('Call onRegionsChange');
                  onRegionsChange(props.user.region)
                }
              }}
            />
            <Input
              addonBefore="Dirección del Rancho"
              type="text"
              onChange={(e) => {
                rancho.direccion = e.target.value;
              }}
            />
            <Input
              addonBefore="Teléfono de contacto"
              type="tel"
              onChange={(e) => {
                rancho.telefono = e.target.value;
              }}
            />
            <Input
              addonBefore="Email de contacto"
              type="email"
              onChange={(e) => {
                rancho.email = e.target.value;
              }}
            />
          </Row>
        </Col>
        <Col xs={24} md={0}>
          <strong>Información del Rancho</strong>
          <Paragraph style={{ margin: 0, textAlign: "start" }}>
            A continuación llena con la información de el Raancho para el que
            vamos a realizar este test.
          </Paragraph>
          <Row style={{ marginTop: 10 }} className="rancho-data">
            <Input
              placeholder="Nombre del Rancho"
              type="text"
              onChange={(e) => {
                rancho.nombre = e.target.value;

                if (props.user.region && region === null) {
                  console.log('Call onRegionsChange');
                  onRegionsChange(props.user.region)
                }
              }}
            />
            <Input
              placeholder="Dirección del Rancho"
              type="text"
              onChange={(e) => {
                rancho.direccion = e.target.value;
              }}
            />
            <Input
              placeholder="Teléfono de contacto"
              type="tel"
              onChange={(e) => {
                rancho.telefono = e.target.value;
              }}
            />
            <Input
              placeholder="Email de contacto"
              type="email"
              onChange={(e) => {
                rancho.email = e.target.value;
              }}
            />
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <Row>
            <Col xs={24} md={8}>
              <Paragraph>
                <strong>
                  Selecciona la región correspondiente al test para obtener los
                  valores correspondientes
                </strong>
              </Paragraph>
            </Col>
            &nbsp;&nbsp;&nbsp;
            {props.user && regiones.length > 0 && (
              <Col xs={24} md={10}>
                <Select
                  style={{
                    marginTop: 10,
                    width: "100%",
                  }}
                  onChange={onRegionsChange}
                  options={
                    props.user.region ?
                      regiones.filter(value => value.id === props.user.region).map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                      :
                      regiones.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                  }
                  defaultValue={props.user.region ? regiones.filter(value => value.id === props.user.region)[0].name : ''}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Card>

      {region && (
        <Card style={{ marginTop: 20, padding: 40 }}>
          <Col span={24}>
            <strong>{page.etapas_title}</strong>
            <div
              className="info_paragraph"
              dangerouslySetInnerHTML={{
                __html: page ? page.etapas_paragraph : "",
              }}
            ></div>
            <Row style={{ marginTop: 10 }}>
              <Checkbox.Group
                onChange={onEtapaChange}
                style={{ marginTop: 10 }}
              >
                <Row>
                  <Col span={24}></Col>
                  {etapas.map((etapa) => {
                    return (
                      <Col
                        span={24}
                        key={etapa.id}
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Col
                          xs={16}
                          md={8}
                          lg={6}
                          style={{ marginTop: 5, padding: 5 }}
                        >
                          <Checkbox value={etapa.id}>{etapa.name}</Checkbox>
                        </Col>
                        <Col
                          xs={8}
                          md={16}
                          lg={18}
                          style={{ marginTop: 5, padding: 5 }}
                        >
                          {selectedEtapas.indexOf(etapa.id) !== -1 && (
                            <Input
                              addonBefore={"#"}
                              style={{ width: 110 }}
                              min={1}
                              max={9999}
                              onChange={(value) => saveUnits(value, etapa)}
                              type="number"
                            />
                          )}
                        </Col>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Row>
          </Col>
        </Card>
      )}

      <h2 style={{ marginTop: 35 }}>
        {/* Los precios deben ser: Precio x Tonelada */}
      </h2>

      {region && (
        <Card style={{ marginTop: 20, padding: 40 }}>
          <Col span={24}>
            <strong>{page.forrajes_title}</strong>
            <div
              className="info_paragraph"
              dangerouslySetInnerHTML={{
                __html: page ? page.forrajes_paragraph : "",
              }}
            ></div>
          </Col>
          <Checkbox.Group
            onChange={onForrajeChange}
            style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
          >
            {forrajes.map((forraje) => {
              return (
                <Row key={forraje.id} span={24}>
                  <Col xs={24} md={8} style={{ marginTop: 5, padding: 5 }}>
                    <Checkbox value={forraje.forraje.id}>
                      {forraje.forraje.name}
                    </Checkbox>
                  </Col>
                  <Col xs={24} md={16} style={{ marginTop: 5, padding: 5 }}>
                    {selectedForrajes.indexOf(forraje.forraje.id) !== -1 && (
                      <Input
                        addonBefore={"$"}
                        style={{ width: 110 }}
                        min={1}
                        max={9999}
                        defaultValue={forraje.price}
                        onChange={(value) => savePrice(value, forraje)}
                        type="number"
                      />
                    )}
                    {selectedForrajes.indexOf(forraje.forraje.id) !== -1 &&
                      forraje.forraje.fsh && (
                        <Input
                          addonBefore={"Proteina"}
                          style={{ width: 150 }}
                          min={1}
                          max={9999}
                          defaultValue={forraje.pc}
                          onChange={(value) => savePC(value, forraje)}
                          type="number"
                        />
                      )}
                    {selectedForrajes.indexOf(forraje.forraje.id) !== -1 &&
                      forraje.forraje.fsh && (
                        <Input
                          addonBefore={"Mat. Seca"}
                          style={{ width: 150 }}
                          min={1}
                          max={9999}
                          defaultValue={forraje.ms}
                          onChange={(value) => saveMS(value, forraje)}
                          type="number"
                        />
                      )}
                    {selectedForrajes.indexOf(forraje.forraje.id) !== -1 &&
                      forraje.forraje.fsh && (
                        <Input
                          addonBefore={"Almidón"}
                          style={{ width: 150 }}
                          min={1}
                          max={9999}
                          defaultValue={forraje.almd}
                          onChange={(value) => saveALMD(value, forraje)}
                          type="number"
                        />
                      )}
                  </Col>
                </Row>
              );
            })}
          </Checkbox.Group>
        </Card>
      )}

      {region && (
        <Card style={{ marginTop: 20, padding: 40 }}>
          <Col span={24}>
            <strong>{page.ingredientes_title}</strong>
            <div
              className="info_paragraph"
              dangerouslySetInnerHTML={{
                __html: page ? page.ingredientes_paragraph : "",
              }}
            ></div>
          </Col>
          <Checkbox.Group
            onChange={onIngredienteChange}
            style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
          >
            {ingredientes.map((ingrediente) => {
              if (ingrediente.ingrediente.name === "Agua" || ingrediente.ingrediente.name === 'AGUA') {
                return null;
              }
              return (
                <Row key={ingrediente.id} span={24} style={{ display: "flex" }}>
                  <Col xs={24} md={8} style={{ marginTop: 5, padding: 5 }}>
                    <Checkbox value={ingrediente.ingrediente.id}>
                      {ingrediente.ingrediente.name}
                    </Checkbox>
                  </Col>
                  <Col xs={24} md={16} style={{ marginTop: 5, padding: 5 }}>
                    {selectedIngredientes.indexOf(
                      ingrediente.ingrediente.id
                    ) !== -1 && (
                        <Input
                          addonBefore={"$"}
                          style={{ width: 110 }}
                          min={1}
                          max={9999}
                          defaultValue={ingrediente.price}
                          onChange={(value) => savePrice(value, ingrediente)}
                          type="number"
                        />
                      )}
                  </Col>
                </Row>
              );
            })}
          </Checkbox.Group>
        </Card>
      )}

      {region && (
        <Card style={{ marginTop: 20, padding: 40 }}>
          <Col span={24}>
            <strong>{page.productos_title}</strong>
            <div
              className="info_paragraph"
              dangerouslySetInnerHTML={{
                __html: page ? page.productos_paragraph : "",
              }}
            ></div>
          </Col>
          <Checkbox.Group
            onChange={onProductoChange}
            style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
          >
            {productos.map((producto) => {
              return (
                <Row key={producto.producto.id} span={24}>
                  <Col xs={24} md={8} style={{ marginTop: 5, padding: 5 }}>
                    <Checkbox value={producto.producto.id}>
                      {producto.producto.name}
                    </Checkbox>
                  </Col>
                  <Col xs={24} md={16} style={{ marginTop: 5, padding: 5 }}>
                    {selectedProductos.indexOf(producto.producto.id) !== -1 && (
                      <Input
                        addonBefore={"$"}
                        style={{ width: 110 }}
                        min={1}
                        max={9999}
                        defaultValue={producto.price}
                        onChange={(value) => savePrice(value, producto)}
                        type="number"
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </Checkbox.Group>
        </Card>
      )}

      {region && (
        <Card style={{ marginTop: 20, padding: 40 }}>
          <Row>
            <Col span={24}>
              <Space>
                <Button className="btn-cancel-test" ghost onClick={onCancel}>
                  Cancelar
                </Button>
                <Button type="primary" ghost onClick={onNext}>
                  Siguiente
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestEtapas: (data) => dispatch(eactions.requestEtapas(data)),
    requestProductos: (data) => dispatch(pactions.requestProductos(data)),
    requestIngredientes: (data) => dispatch(iactions.requestIngredientes(data)),
    requestForrajes: (data) => dispatch(factions.requestForrajes(data)),
    requestRegiones: (data) => dispatch(ractions.requestRegiones(data)),

    requestStep0Page: (data) => dispatch(pages_actions.requestStep0Page(data)),

    logAction: (data) => dispatch(actions.logAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UNewTest);
