import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {AsyncStorage} from 'AsyncStorage';

import * as actions from '../actions/user'

import {
    Row,
    Col,
    Button,
    Popconfirm,
    Table,
    Layout,
    Tabs,
    Card
} from "antd";
import { UserOutlined, TeamOutlined, HistoryOutlined } from '@ant-design/icons';
import {PlusOutlined} from '@ant-design/icons';

/*
const consumers = [
    {
      key: '0',
      name: 'José',
      company: 'Cow Wow',
      email: 'jose@cowwow.com',
      status: 'active'
    }
]
*/

const defaultColumns = [
    {
        title: 'Nombre',
        dataIndex: 'name',
        width: '20%',
        editable: false,
    },
    {
        title: 'Empresa',
        dataIndex: 'company',
        width: '20%',
        editable: false,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        width: '20%',
        editable: false,
    },
    {
        title: 'Estatus',
        dataIndex: 'status',
        width: '20%',
        editable: false,
    },
    {
        title: 'Acción',
        dataIndex: 'action',
        width: '20%',
    },
    
]

const EDashboard = (props) => {
    const navigate = useNavigate();
    const [consumers, setConsumers] = useState([])
    const [componentReady, setComponentReady] = useState(false)

    const getAsyncProfile = async () => {
      const data = await AsyncStorage.getItem('profile')
      if (typeof(data) === 'undefined'){
        return {}
      }
      return JSON.parse(data)
    } 
  
    useEffect(()=>{
      if(!props.user || !props.user.authenticated){
        getAsyncProfile().then(r => {
          console.log('asyncProfile',r);
          if(!r || !r.authenticated){
            navigate('/login')
          }
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
      const getUsersList = async () => {
        const usuarios = await props.usersList()
        if (usuarios.type === 'FETCHING_USER_DATA_SUCCESS'){
          setConsumers(usuarios.data.items.map((item)=>{return({
            key: item.id,
            name: item.first_name + ' ' + item.last_name,
            company: item.empresa,
            email: item.email,
            status: item.is_active ? 'Activo' : 'Inactivo'
          })}))
        }
        console.log('usuarios',usuarios);
      }
      getUsersList();
      setComponentReady(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    defaultColumns[defaultColumns.length-1].render = (_, record) =>
        consumers.length >= 1 ? (
        <Popconfirm title="Confirmar" onConfirm={() => handleAccess(record.key)}>
          <span className='asLink'>Acceder</span>
        </Popconfirm>
        ) : null;
    const handleAccess = (key) => {
        console.log('handleAccess',key);
        navigate(`/asuser/${key}/results/0`)
    };
    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
          }),
        };
    });

    const TabsDashboard = (props) => (
        <Tabs items={[
          {
              label: (<span><TeamOutlined /> Usuarios</span>),
              key: 'consumers',
              children: <TabConsumers />
          },
          {
              label: (<span><HistoryOutlined /> Historial</span>),
              key: 'history',
          },
          {
              label: (<span><UserOutlined /> Mi perfil</span>),
              key: 'my-profile'
          }
      ]} />
    )

    const TabConsumers = (props) => {
      return (
        <Card style={{marginTop: 20, padding: 40}} key={"consumers"}>
          <Row>
              <Col span={24}>
                <Table
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={consumers}
                  columns={columns}
                />
              </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button type="primary" icon={<PlusOutlined />}>
                Agregar
              </Button>
            </Col>
          </Row>
        </Card>
      )
    }

    return componentReady && ( 
        <Layout>
          <TabsDashboard />
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
      usersList: (data) => dispatch(actions.usersList(data)),      
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EDashboard)
