import { useLocation } from "react-router-dom";
import { Layout, Row, Col } from "antd";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const currentYear = new Date().getFullYear();
  let { pathname } = useLocation();

  pathname = pathname.replace("/", "");

  return (
    <AntFooter style={{ textAlign: pathname === 'login' ? 'center' : 'right' }}>
      <Row>
        <Col xs={24}>
          <div className="copyright">
            Todos los derechos reservados ADM&reg; {currentYear} 
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
